import React,{useContext} from 'react'
import { Box, Button,  List, ListItem,Text,useToast} from "@chakra-ui/core";
import {MyContext} from '../Context'

export default function DetalleOrden({orden,history}) {
    const context=useContext(MyContext)
    const toast=useToast()
    const go = path => history.push(path)
    const pagar=()=>{
        context.setOrden(orden)
        go('/pago')
    }
    const DetalleOrden=()=>{
        context.setOrden(orden)
        go(`/detalle/${orden.numero}`)
    }
    const cancelOrder=()=>{
        const {msg}=context.cancelOrder(orden)
        if (!msg) {
            toast({
            title: "Operación exitosa",
            description: 'Orden cancelada.',
            status: "success",
            duration: 4000,
            isClosable: true,
            position:'top-right'
          })
        } else {
          toast({
            title: 'Operación errónea', 
            description: msg, 
            status: 'error',
            duration: 5000, 
            isClosable: true,
            position:'top-right'
          })
        }
    }
    return (
        <MyContext.Consumer>
          {context => {
    return (
        <Box display='flex' flexDirection='column' color='color5' w='100%' marginBottom='20px' borderRadius='20px' border='solid 1px teal' >
            <Box w='100%' display='flex' dir='row' alignItems='center' justifyContent='space-between' bg='color3' color='color6' padding='5px 10px' borderTopLeftRadius='20px' borderTopRightRadius='20px'>
                <Text fontWeight='bold'>Orden # {orden.numero}</Text>
                <Text fontWeight='bold'>Estatus : {orden.estatus}</Text>
                {orden.cancelable && <Button size="sm" borderRadius='100px' backgroundColor='color5' color='color6' onClick={()=>cancelOrder()} >Cancelar</Button>}
            </Box>
            <Box w='100%' display='flex' dir='row' justifyContent='space-between' padding='10px' textAlign='left' flexWrap='wrap' >
                <Box w={{sm:'40%',md:'30%'}}>
                    <Text fontWeight='bold'>Total de piezas: {orden.totalPiezas}</Text>
                    <List as="ol" styleType="decimal">
                        {orden.archivos.map((archivo,index)=>
                            <ListItem  fontSize='sm' key={index}>{archivo.name}</ListItem>
                        )}
                    </List>
                    <Box display='flex' justifyContent='center' w='100%'>
                        <Button size="sm" mt={5} justifySelf='center' borderRadius='100px' backgroundColor='color3' color='color6'  onClick={(()=>DetalleOrden())}>Detalle</Button>
                    </Box>
                </Box>
                <Box w={{sm:'40%',md:'30%'}}>
                    <Text fontWeight='bold'>Fecha de envio:</Text>
                    {!orden.pagoPendiente?<Text fontSize='sm'>{orden.fechaEnvio}</Text>:
                    <Text fontSize='sm'>Pendiente</Text>
                    }
                    <Text fontWeight='bold'>Enviar a:</Text>
                    {!orden.direccion?
                    <Text resize='none' w='150px' fontSize='sm'>Universidad Panamericana</Text>
                    :<Text resize='none' w='150px' fontSize='sm'>{`${orden.direccion.calle} Ext. ${orden.direccion.ext} Int. ${orden.direccion.int}, Col. ${orden.direccion.colonia}, Del. ${orden.direccion.delegacion}, C.P. ${orden.direccion.cp}, ${orden.direccion.estado},México.`}</Text>
                    }
                </Box>
                <Box w={{sm:'40%',md:'30%'}}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text fontSize='sm'>Subtotal:</Text> <Text fontSize='sm'>${orden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text fontSize='sm'>Envio:</Text> <Text fontSize='sm'>${orden.envio.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text fontSize='sm'>Cargos Adicionales:</Text> <Text fontSize='sm'>${orden.cargosAdicionales.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' fontWeight='bold'><Text>Total:</Text> <Text>${orden.total.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' fontWeight='bold' margin='10px 0px'><Text>Por pagar:</Text> <Text>${(orden.total-orden.totalPagado).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text> </Box>
                    {orden.pagoPendiente && <Box display='flex' justifyContent='center' w='100%'> <Button size="sm"  borderRadius='100px' backgroundColor='color3' color='color6' onClick={()=>pagar()}>Pagar</Button></Box> }
                </Box>
                
            </Box>            
        </Box>
    )
    }}
    </MyContext.Consumer>
  )
}

