import React ,{useContext,useEffect} from 'react';
import {MyContext} from '../Context'
import FileViewer2 from '../components/fileViewer2';
import {Box,Button,Text,useToast,Tooltip,Spinner} from '@chakra-ui/core'
import { FaPlus } from "react-icons/fa";
import Auth from '../components/auth'

function Cotizador({history}) {
  const toast = useToast()
  const context=useContext(MyContext)
  useEffect(()=>{
    const idUser=localStorage.getItem('idUser')
    if (idUser && !context.state.isLogged && context.state.feed) {
      context.restoreData() 
    }
  })
  const addFile=async()=>{
    const {msg}=await context.addFileCorte()
    if (msg){
      toast({
        title: 'Falta cargar archivo(s)',
        description: msg,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position:'bottom-left'
      })
    }
      window.scrollBy(0, window.innerHeight)
    
  }
  const order=async (e)=>{
    const resp=await context.orderCorte(e)
    setLoading(true)
    if (resp.msg){
      toast({
        title: 'Falta cargar archivo(s)',
        description: resp.msg,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position:'bottom-right'
      })
    }
    else if(resp.err){
      toast({
        title: 'Error',
        description: resp.err,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position:'bottom-right'
      })
    }
    else {
    go('/pago')
    }
    setLoading(false)
  }
  const [loading,setLoading]=React.useState(false);
  const go = path => history.push(path)
  return (
  <>
    <Box marginTop='30px'>
      {context.state.formArchivosCorte.map((formArchivo, index) => {
                            return (
                              <FileViewer2 key={index} formArchivo={formArchivo} index={index} />
                            );
                          })
                          }
      <Box backgroundColor='transparent' width='100vw' height='40px' />
       
      <Box pos='fixed' backgroundColor='color2' bottom={0} width='100vw'  zIndex='99' display='flex' justifyContent='space-around' padding='10px'>
        <Button size="sm" borderRadius='100px' backgroundColor='color3' color='color6' leftIcon={FaPlus} onClick={()=>addFile()}>Agregar Archivo</Button>
        <Text fontWeight='bold' color='color5'>Total: ${context.state.totalCorte.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
        {context.state.isLogged ? <Button size="sm" borderRadius='100px' width='100px' backgroundColor='color3' color='color6' onClick={(e)=>order(e)}>Ordenar</Button>:
        <Tooltip hasArrow label="Inicia sesión primero" zIndex='100' placement="top" color="color3" bg='color6'>
          <Box borderRadius='100px' display='flex'  backgroundColor='color3' color='color6' width='100px' justifyContent='center' alignItems='center' >
            <Auth className='menuItems' fontWeight='600' fontSize='14px' value='Ordenar'/>
          </Box>
        </Tooltip>
        }
      </Box>
    </Box>
    {loading && 
      <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
          <Text color='color1' fontWeight='bold' fontSize='5xl'>Procesando</Text>
          <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="teal.700"
          size="xl"
          />
      </Box>
    }
  </>


);
}

export default Cotizador;
