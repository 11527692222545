import React,{useContext,useEffect} from 'react'
import {MyContext} from '../Context'
import {Text, Box,Button,useToast, FormControl,Stack,Input, FormLabel,Divider, Tabs,Tab, TabList, TabPanels, TabPanel,Spinner} from "@chakra-ui/core";
import DetalleOrden from '../components/DetalleOrden'
import { FaUserAlt } from "react-icons/fa";


function Profile({history}) {
    const context=useContext(MyContext)
    useEffect(()=>{
        const idUser=localStorage.getItem('idUser')
        if (idUser && !context.state.isLogged && context.state.feed) {
            context.restoreData()
        }
        /*if (!context.state.isLogged && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
                context.restoreData()
                .catch((err)=>history.push('/'))
            }else{
                history.push('/')
            }
        }*/
      })
    const toast = useToast()
    const [edit,setEdit]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const {loggedUser}=context.state
    const transferCredit=async(e)=>{
        e.preventDefault()
        setLoading(true)
        if(context.state.formTransferCredit.credits<=context.state.loggedUser.credits){
            const resp= await context.transferCredit(e)
            toast({
            title: "Transferencia de Crédito",
            description: resp.msg,
            status: resp.estatus,
            duration: 4000,
            isClosable: true,
            position:'bottom-left'
            })
            window.scrollBy(0, -window.innerHeight);
        }
        else {
            toast({
                title: 'Transferencia de Crédito', 
                description: 'No tienes suficiente saldo para hacer la transferencia.', 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
        }
        setLoading(false); 
    }

    const submitChangePass=async(e)=>{
        e.preventDefault()
        setLoading(true); 
        if(context.state.formChangePass.password.localeCompare(context.state.formChangePass.passwordConfirm)===0){
            const msg= await context.submitChangePass(e)
            if (msg.localeCompare('Cambio exitoso.')===0)  {
                toast({
                title: "Contraseña cambiada",
                description: msg,
                status: "success",
                duration: 4000,
                isClosable: true,
                position:'bottom-left'
            })
            window.scrollBy(0, -window.innerHeight);
            } else {
            toast({
                title: 'Contraseña no cambiada', 
                description: msg, 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
            }
        }
        else {
            e.preventDefault()
            toast({
                title: 'Valida los datos', 
                description: 'Las contraseñas no coinciden', 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
        }
        setLoading(false);  
    }
    const changeAddress=(e)=>{
        setLoading(true); 
        context.changeAddress(e)
        .then(resp=>{
            toast({
                title: 'Actualización', 
                description: resp.msg, 
                status: resp.code,
                duration: 3000, 
                isClosable: true,
                position:'bottom-left'
            })
            setEdit(false);
            setLoading(false); 
            window.scrollBy(0, -window.innerHeight);
            
        })
        .catch(err=>{
            toast({
                title: 'Actualización', 
                description: 'No se pudo actualizar. Intenta más tarde.', 
                status: 'error',
                duration: 3000, 
                isClosable: true,
                position:'bottom-left'
            })
            setEdit(false);
            setLoading(false); 
            window.scrollBy(0, -window.innerHeight);
        })
    }

    const credito=context.state.isLogged?loggedUser.credits:0
   
    return (
        <>
       {context.state.isLogged && <Box display='flex' flexDir={{sm:'column',md:'row'}} justifyContent={{sm:'center',md:'space-around'}} alignItems={{sm:'center',md:'flex-start'}} w='100vw' color='color5'>
            <Box textAlign='left' w={{sm:'80vw',md:'20vw'}} display='flex' flexDirection={{sm:'column',md:'column'}} paddingTop='50px'>
                <Box>
                    <Box mb='15px' display='flex' flexDir='row' fontWeight='bold'> <Box as={FaUserAlt} mr={2} /> {loggedUser.name}</Box>
                    <Text>Email: {loggedUser.email}</Text>
                    <Text>Teléfono: {loggedUser.phone}</Text>
                    <Text>Saldo disponible: ${Number(credito).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                </Box>
                <Divider margin='15px 0' border='solid 3px' borderColor="color5" backgroundColor="color5" width='100%' orientation={{sm:'vertical',md:'horizontal'}}/>
                <Box>
                <Text fontWeight='bold'>Dirección de envio</Text>
                {!edit &&
                <Text  w='100%'>{`${loggedUser.direccion.calle} Ext. ${loggedUser.direccion.ext} Int. ${loggedUser.direccion.int}, Col. ${loggedUser.direccion.colonia}, Del. ${loggedUser.direccion.delegacion}, C.P. ${loggedUser.direccion.cp}, ${loggedUser.direccion.estado}, México.`}</Text>
                 }
                {!edit && <Button onClick={()=>setEdit(!edit)} type="submit" size="sm" mt={5} justifySelf='center' borderRadius='100px' backgroundColor='color3' color='color6'>
                                  Editar
                              </Button>
                }
                {edit && <> 
                    <FormControl as="form" onSubmit={e=>changeAddress(e)} display="flex" h="60%" justifyContent="center" w="100%" p={4}>
                        <Stack color="color5" width="200px">
                            <FormLabel htmlFor="calle">Calle*</FormLabel>
                            <Input size='sm' id="calle" name="calle" value={context.state.formDireccion.calle} onChange={(e) => context.handleInput(e, 'formDireccion')} type="text" roundedLeft="0" isRequired />
                            <FormLabel htmlFor="ext">Ext.*</FormLabel>
                            <Input size='sm' id='ext' name="ext" value={context.state.formDireccion.ext} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" roundedLeft="0" isRequired/>
                            <FormLabel htmlFor="int">Int.</FormLabel>
                            <Input size='sm' id="int" name="int" value={context.state.formDireccion.int} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" roundedLeft="0" />
                            <FormLabel htmlFor="cp">Código Postal*</FormLabel>
                            <Input size='sm' id="cp" maxLength="5" minLength="5" name="cp" value={context.state.formDireccion.cp} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0"  isRequired/>
                            <FormLabel htmlFor="colonia" justifySelf="left">Colonia*</FormLabel>
                            <Input size='sm' id="colonia" name="colonia" value={context.state.formDireccion.colonia} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0"  isRequired/>
                            <FormLabel htmlFor="estado" justifySelf="left">Estado*</FormLabel>
                            <Input size='sm' id="estado" name="estado" value={context.state.formDireccion.estado} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0" isRequired/>
                            <FormLabel htmlFor="delegacion" justifySelf="left">Delegacion/Municipio*</FormLabel>
                            <Input size='sm' id="delegacion" name="delegacion" value={context.state.formDireccion.delegacion} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0"  isRequired/>
                            <Button isDisabled={loading} type="submit" size="sm" mt={5} justifySelf='center' borderRadius='100px' backgroundColor='color3' color='color6'>
                                Guardar
                            </Button>
                        </Stack>
                    </FormControl>
                </>}
                </Box>
                <Divider margin='15px 0' border='solid 3px' borderColor="color5" backgroundColor="color5" width='100%' orientation={{sm:'vertical',md:'horizontal'}}/>
                <Box margin='0' padding='0'textAlign='left'>
                    <FormControl isRequired as="form" onSubmit={(e)=>submitChangePass(e)} display="flex" h="60%" justifyContent="center" w="100%">
                          <Stack color="color5" width="200px">
                            <Text fontWeight='bold'>Cambiar Contraseña </Text>
                              <FormLabel htmlFor="fpass" fontWeight='normal'>Nueva contraseña</FormLabel>
                              <Input size='sm' id="fpass" name="password" value={context.state.formChangePass.password} onChange={(e) => context.handleInput(e, 'formChangePass')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                              <FormLabel htmlFor="fpassconf" fontWeight='normal' justifySelf="left">Confirmar nueva contraseña</FormLabel>
                              <Input size='sm' id="fpassconf" name="passwordConfirm" value={context.state.formChangePass.passwordConfirm} onChange={(e) => context.handleInput(e, 'formChangePass')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                              <Button isDisabled={loading} type="submit"size="sm" mt={5} width='80px' padding='10px' margin='0 auto' borderRadius='100px' backgroundColor='color3' color='color6'>
                                  Cambiar
                              </Button>
                          </Stack>
                        </FormControl>
                </Box>
                <Divider margin='15px 0' border='solid 3px' borderColor="color5" backgroundColor="color5" width='100%' orientation={{sm:'vertical',md:'horizontal'}}/>
                <Box margin='0' padding='0'textAlign='left'>
                    <FormControl isRequired as="form" onSubmit={(e)=>transferCredit(e)} display="flex" h="60%" justifyContent="center" w="100%">
                          <Stack color="color5" width="200px">
                            <Text fontWeight='bold'>Transferir Saldo</Text>
                              <FormLabel htmlFor="fcredits" fontWeight='normal'>Saldo a transferir</FormLabel>
                              <Input size='sm' id="fcredits" name="credits" value={context.state.formTransferCredit.credits} onChange={(e) => context.handleInput(e, 'formTransferCredit')} color="color5" type="number" rounded="2"  isRequired/>
                              <FormLabel htmlFor="fuser" fontWeight='normal' justifySelf="left">Usuario a transferir</FormLabel>
                              <Input size='sm' id="fuser" name="email" value={context.state.formTransferCredit.email} onChange={(e) => context.handleInput(e, 'formTransferCredit')} placeholder="correo@mail.com" color="color5" type="email" isRequired/>
                              <Button isDisabled={loading} type="submit"size="sm" mt={5} width='80px' padding='10px' margin='0 auto' borderRadius='100px' backgroundColor='color3' color='color6'>
                                  Transferir
                              </Button>
                          </Stack>
                        </FormControl>
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' padding='15px' paddingTop='50px' w={{sm:'80vw',md:'70vw'}}>
                <Text textAlign='left' color='color5' fontWeight='bold' fontSize='2xl'>Mis Ordenes</Text>
                <Divider border='solid 3px' color='color5' borderColor="color5" width='100%'/>
                <Tabs size='md' variantColor='teal'>
                    <TabList>
                        <Tab>Impresión 3D</Tab>
                        <Tab>Corte Láser</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel paddingTop={5}>
                                {!context.state.loggedUser.ordenes.length && <Box display='flex' alignItems='center' justifyContent='center' h='60vh'>
                                    <Text fontSize='lg' textAlign='center'>Aún no tienes ordenes.</Text>
                                </Box>}
                                {context.state.loggedUser.ordenes.length && 
                                    <Box mb={15} overflowY='scroll' height='600px' className='barras2'>
                                        {loggedUser.ordenes.map((orden,index)=>
                                            <DetalleOrden key={index} orden={orden} history={history} />
                                        )}
                                    </Box>
                                }
                        </TabPanel>
                        <TabPanel paddingTop={5}>
                                {!context.state.loggedUser.ordenesCorte.length && <Box display='flex' alignItems='center' justifyContent='center' h='60vh'>
                                    <Text fontSize='lg' textAlign='center'>Aún no tienes ordenes.</Text>
                                </Box>}
                                {context.state.loggedUser.ordenes.length && 
                                    <Box mb={15} overflowY='scroll' height='600px' className='barras2'>
                                        {loggedUser.ordenesCorte.map((orden,index)=>
                                            <DetalleOrden key={`c_${index}`} orden={orden} history={history} />
                                        )}
                                    </Box>
                                }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
       }
       {loading && 
                <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
                    <Text color='color1' fontWeight='bold' fontSize='5xl'>Procesando</Text>
                    <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="teal.700"
                    size="xl"
                    />
                </Box>
            }
       </>
    )
}

export default Profile
