import React,{useContext,useEffect,useState} from 'react'
import {MyContext} from '../Context'
import {useDisclosure,Text, Box,Button,Select,Input,useToast,Checkbox, Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter, ModalBody, ModalCloseButton,Spinner,Divider} from "@chakra-ui/core";
import DetalleArchivo from '../components/DetalleArchivo'
import PaypalCheckoutButton from '../components/PaypalCheckoutButton'
import DireccionEnvio from  '../components/DireccionEnvio'

function Pago({history}) {
    const toast=useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [procesando,setProcesando]=useState(false);
    const [envioUp,setEnvioUp]=useState(false);
    const context=useContext(MyContext)
    useEffect(()=>{
        if (!context.state.isLogged  && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
            context.restoreData()
            .then(resp=>{
                const orden=localStorage.getItem('orden')
                if(!orden){
                    history.push('/perfil')
                }
            })
            .catch((err)=>history.push('/')) 
            }else{
                history.push('/')
            }  
        }
      })
    const {formOrden}=context.state
    const handleInput=async (e)=>{
        await context.handleInput(e,'formOrden',)
    }
    const applyCredits=async ()=>{
        setProcesando(true);
        if(context.state.loggedUser.credits<=0){
            toast({
                title: 'Saldo insuficientes', 
                description: 'No hay saldo disponible para ser aplicados', 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-right'
              })
        }
        else{
            const msg=await context.applyCredits()
            if(!msg){
                toast({
                    title: "Operación exitosa.",
                    description: "Saldo aplicado.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position:'bottom-right'
                  })
            }
            else{
                toast({
                    title: 'Operación fallida', 
                    description: msg, 
                    status: 'error',
                    duration: 5000, 
                    isClosable: true,
                    position:'bottom-right'
                  })
            }
        }
        setProcesando(false);
    }
    const handleEnvioUp=async (e)=>{
        setEnvioUp(!envioUp);
        await context.handleEnvioUP(e);
    }
    const handleTipoEnvio=async(e)=>{
        await context.handleTipoEnvio(e);
    }
    const handleCheck=async(e)=>{
        const d={target:{value:e.target.checked.toString().toLowerCase()==='true',
            name:e.target.name}}
        context.handleInput(d,'formOrden')
    }
    const hasEnvioUp=context.state.loggedUser && context.state.loggedUser.email.indexOf('@up.edu.mx')>-1;
    return (
    <>
        <Box color="color5" display='flex' flexDirection='column' alignItems='center' >
            <Text as='h1' fontSize='2xl' margin='20px 0' fontWeight='bold'>Resumen de tu orden</Text>
            <Box borderRadius='20px' border='solid 2px teal' textAlign='left' width='90vw'>
                <Box  borderTopLeftRadius='20px' borderTopRightRadius='20px' color='color6' backgroundColor='color3' padding='10px 20px'>
                    <Text fontSize='xl' fontWeight='bold'>Orden {formOrden.numero}</Text>
                </Box>
                <Box display='flex' flexDirection={{sm:'column',md:'row'}} justifyContent='space-between' padding='20px'paddingTop='5px'>
                    <Box  width={{sm:'90%',md:'60%'}}>
                        {formOrden.archivos.map((archivo,index)=>
                            <DetalleArchivo key={index} archivo={archivo}  corte={formOrden.corte}/>
                        )}
                    </Box>
                    <Box w={{sm:'90%',md:'40%'}}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Fecha estimada de impresión terminada:</Text> <Text fontWeight='bold' >{formOrden.fechaEnvio}</Text></Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Subtotal:</Text> <Text>${formOrden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Envio estimado:</Text> <Text>${formOrden.envio.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        {formOrden.cargosAdicionales?
                            <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
                                <Text>Cargos Adicionales:</Text> 
                                <Text>${formOrden.cargosAdicionales.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                            </Box>:
                            <></>
                        }
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Total:</Text> <Text>${formOrden.total.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        
                        <Box display='flex' flexDirection='row'  justifyContent='space-between' width='100%' margin='5px 0' fontWeight='bold'><Text>Total a pagar:</Text> <Text>${(formOrden.total-formOrden.totalPagado).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text> </Box>                        

                    </Box>
                </Box>
                
                <Box display='flex' flexDir='column' alignItems='center'>
                    <Divider margin='15px 0' border='solid 3px' borderColor="color5" backgroundColor="color5" width='90%'/>
                    <Text as='h3' textAlign='center' fontSize='2xl' margin='20px 0' fontWeight='bold'>Información para envío y pago</Text>
                </Box>
                <Box display='flex' flexDirection={{sm:'column',md:'row'}} justifyContent='space-between' padding='20px'paddingTop='5px'>
                    <Box  width={{sm:'90%',md:'60%'}}>
                        <Box display='flex' flexDirection='column' justifyContent='space-between'>
                            <Box display='flex' flexDirection='row' width='100%'><Text>Fecha estimada de impresión terminada:</Text> <Text fontWeight='bold' >{formOrden.fechaEnvio}</Text></Box>
                            <Box>
                                {hasEnvioUp && 
                                    <Checkbox margin='10px 0' colorScheme="teal" name='envioUp' isChecked={envioUp} onChange={(e) => handleEnvioUp(e)}>
                                        Envio UP (sin costo, el miércoles de la semana siguiente de que haces el pago)
                                    </Checkbox>
                                }
                                {!envioUp &&
                                    <DireccionEnvio />
                                }
                            </Box>
                            {!envioUp && <>
                                    <label mt={1} mb={1} htmlFor="tipoEnvio">Elige el tipo de envío*</label>
                                    <Select size='sm' value={formOrden.tipoEnvio} w='70%' name="tipoEnvio" className="select"  onChange={(e)=>handleTipoEnvio(e)} isRequired>
                                        <option value='' >Selecciona un tipo de envío</option>
                                        {formOrden.tiposEnvio.map((tipo,index)=>
                                            <option key={index} value={tipo.name}>{tipo.name} (${tipo.value})</option>
                                        )}      
                                    </Select>
                                </>
                            }
                            
                        
                        </Box>
                    </Box>
                    <Box w={{sm:'90%',md:'40%'}}>
                        <Box mt={3} mb={3} display='flex' flexDir='column' w='80%' alignContent='center'>
                            <Text fontSize='sm'>Nombre del Proyecto</Text>
                            <Input  type='text' name='descripcion'  value={formOrden.descripcion}
                                    onChange={e => handleInput(e)}
                                    placeholder="Ponle nombre a tu proyecto :)"
                                    size="sm"/>
                        </Box>
                        <Box display='flex' justifyContent='center' w='80%'>
                            <Button size="sm" borderRadius='100px' width='150px' margin='5px 0' backgroundColor='color3' color='color6'  onClick={()=>context.updateOrder(formOrden)}>Guardar Nombre</Button>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Subtotal:</Text> <Text>${formOrden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Envio:</Text> <Text>${formOrden.envio.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        {formOrden.cargosAdicionales?
                            <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
                                <Text>Cargos Adicionales:</Text> 
                                <Text>${formOrden.cargosAdicionales.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                            </Box>:
                            <></>
                        }
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Total:</Text> <Text>${formOrden.total.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        
                        <Box marginTop='5px' display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Saldo disponible:</Text> 
                            {context.state.loggedUser && <Text>${context.state.loggedUser.credits.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>}    
                        </Box>
                        {context.state.loggedUser && context.state.loggedUser.credits && formOrden.pagoPendiente?<>
                            <Box display='flex' justifyContent='center' w='100%'>
                                <Button size="sm" isDisabled={procesando} borderRadius='100px' width='150px' margin='5px 0' backgroundColor='color3' color='color6'  onClick={onOpen}>Aplicar saldo</Button>
                            </Box>
                            <Modal preserveScrollBarGap isCentered={true} isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                <ModalHeader>Aplicar saldo</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                ¿Estas seguro que quieres usar ${formOrden.total.toLocaleString('es-MX')} de tu saldo disponible?
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" size="sm" borderRadius='100px' width='150px' margin='5px 0' color='color3' mr={3} onClick={onClose}>Cancelar</Button>
                                    <Button isDisabled={procesando} size="sm" borderRadius='100px' width='150px' margin='5px 0' backgroundColor='color3' color='color6' onClick={()=>applyCredits()}>Aplicar saldo</Button>
                                </ModalFooter>
                                </ModalContent>
                            </Modal>
                            </>:
                            <><br /></>
                        }
                        
                        <Box display='flex' flexDirection='row'  justifyContent='space-between' width='100%' margin='5px 0' fontWeight='bold'><Text>Total a pagar:</Text> <Text>${(formOrden.total-formOrden.totalPagado).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text> </Box>
                        
                        {formOrden.pagoPendiente && !procesando && <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' w='100%'>
                                <Checkbox margin='10px 0' colorScheme="teal" name='factura' isChecked={formOrden.factura} onChange={(e) => handleCheck(e)}>
                                    Requiero factura
                                </Checkbox>
                                <PaypalCheckoutButton order={formOrden} />
                            </Box>} 
                            
                            {formOrden.factura &&
                                <Text fontWeight='bold'>Nota: Solicitaste factura del pago hecho con PayPal.</Text>
                            }
 
                            <Text mt={5}  w='100%'>La dirección de envío es: {`${formOrden.direccion.calle} Ext. ${formOrden.direccion.ext}${formOrden.direccion.int?' Int. '+formOrden.direccion.int:''}, Col. ${formOrden.direccion.colonia}, Del. ${formOrden.direccion.delegacion}, C.P. ${formOrden.direccion.cp}, ${formOrden.direccion.estado}, México.`}</Text>

                            {!formOrden.pagoPendiente && <Text fontWeight='bold' fontSize="3xl" mt={6}>¡Orden exitosa!</Text>
                            }
                            {!formOrden.pagoPendiente && <Text fontWeight='bold' fontSize="3xl">Gracias por tu compra</Text>
                            }
                    </Box>
                </Box>
            </Box>
            <Button margin='10px 0' size="sm" borderRadius='100px' backgroundColor='color3' color='color6' onClick={()=>history.push('/perfil')}>Ir al perfil</Button>        
        </Box>
        {procesando && 
            <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
                <Text color='color1' fontWeight='bold' fontSize='5xl'>Procesando</Text>
                <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="teal.700"
                size="xl"
                />
            </Box>
        }
    </>
);
}

export default Pago
