import React,{useContext,useEffect} from 'react'
import {MyContext} from '../Context'
import {Flex,Text, Box,Button,useToast, FormControl,Stack,Input, FormLabel,Divider} from "@chakra-ui/core";
import LateralMenu from '../components/LateralMenu'


function ProfileAdmin({history}) {
    const context=useContext(MyContext)
    useEffect(()=>{
        if (!context.state.isLogged && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
                context.restoreData()
                .catch((err)=>history.push('/'))
                 
            }else{
                history.push('/')
            }
        }
      })
    const toast = useToast()
    const [loading,setLoading]=React.useState(false)
    const transferCredit=async(e)=>{
        e.preventDefault()
        setLoading(true)
        if(context.state.formTransferCredit.credits<=context.state.loggedUser.credits){
            const resp= await context.transferCredit(e)
            toast({
            title: "Transferencia de Crédito",
            description: resp.msg,
            status: resp.estatus,
            duration: 4000,
            isClosable: true,
            position:'bottom-left'
            })
            window.scrollBy(0, -window.innerHeight);
        }
        else {
            toast({
                title: 'Transferencia de Crédito', 
                description: 'No tienes suficiente saldo para hacer la transferencia.', 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
        }
        setLoading(false); 
    }

    const submitChangePass=async(e)=>{
        e.preventDefault()
        setLoading(true); 
        if(context.state.formChangePass.password.localeCompare(context.state.formChangePass.passwordConfirm)===0){
            const msg= await context.submitChangePass(e)
            if (msg.localeCompare('Cambio exitoso.')===0)  {
                toast({
                title: "Contraseña cambiada",
                description: msg,
                status: "success",
                duration: 4000,
                isClosable: true,
                position:'bottom-left'
            })
            window.scrollBy(0, -window.innerHeight);
            } else {
            toast({
                title: 'Contraseña no cambiada', 
                description: msg, 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
            }
        }
        else {
            e.preventDefault()
            toast({
                title: 'Valida los datos', 
                description: 'Las contraseñas no coinciden', 
                status: 'error',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
        }
        setLoading(false);  
    }
    const {orders}=context.state
    return (
        <>
            {context.state.isLogged && context.state.isAdmin && 
                <Box display='flex' flexDir='row' justifyContent='space-between' alignItems='flex-start' w='100vw' color='color5'>
                    <LateralMenu width={'15vw'} />
                    <Flex flexDir='column' w='80vw' paddingRight='5vw'>
                        <Text textAlign='left' color='color5' fontWeight='bold' fontSize='2xl'>Resumen</Text>
                        <Divider border='solid 3px' color='color5' borderColor="color5" width='100%'/>
                        {context.state.feed && orders.impresion &&
                         <Flex flexDir='row' h='30vh' justifyContent='space-around'>
                            <Flex flexDir='column' justifyContent='space-around'>
                                <Text fontWeight='bold' fontSize='xl'>Histórico</Text>
                                <Text>Total: {orders.impresion.length+orders.corte.length}</Text>
                                <Text>Impresión 3D:{orders.impresion.length}</Text>
                                <Text>Corte láser:{orders.corte.length}</Text>
                            </Flex>
                            <Flex flexDir='column' justifyContent='space-around'>
                                <Text fontWeight='bold' fontSize='xl'>Recibidas</Text>
                                <Text>Total: {orders.impresionRecibida.length+orders.corteRecibida.length}</Text>
                                <Text>Impresión 3D:{orders.impresionRecibida.length}</Text>
                                <Text>Corte láser:{orders.corteRecibida.length}</Text>
                            </Flex>
                            <Flex flexDir='column' justifyContent='space-around'>
                                <Text fontWeight='bold' fontSize='xl'>Manufacturando</Text>
                                <Text>Total: {orders.impresionImprimiendo.length+orders.corteImprimiendo.length}</Text>
                                <Text>Impresión 3D:{orders.impresionImprimiendo.length}</Text>
                                <Text>Corte láser:{orders.corteImprimiendo.length}</Text>
                            </Flex>
                        </Flex>
                        }
                        <Divider mt='25px' mb='25px' border='solid 3px' color='color5' borderColor="color5" width='100%'/>
                        <Flex flexDir='row' justifyContent='space-around' alignItems='center'>
                            <Box margin='0' padding='0'textAlign='left'>
                                <FormControl isRequired as="form" onSubmit={(e)=>submitChangePass(e)} display="flex" h="60%" justifyContent="center" w="100%">
                                    <Stack color="color5" width="200px">
                                        <Text fontWeight='bold'>Cambiar Contraseña </Text>
                                        <FormLabel htmlFor="fpass" fontWeight='normal'>Nueva contraseña</FormLabel>
                                        <Input size='sm' id="fpass" name="password" value={context.state.formChangePass.password} onChange={(e) => context.handleInput(e, 'formChangePass')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                                        <FormLabel htmlFor="fpassconf" fontWeight='normal' justifySelf="left">Confirmar nueva contraseña</FormLabel>
                                        <Input size='sm' id="fpassconf" name="passwordConfirm" value={context.state.formChangePass.passwordConfirm} onChange={(e) => context.handleInput(e, 'formChangePass')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                                        <Button isDisabled={loading} type="submit"size="sm" mt={5} width='80px' padding='10px' margin='0 auto' borderRadius='100px' backgroundColor='color3' color='color6'>
                                            Cambiar
                                        </Button>
                                    </Stack>
                                    </FormControl>
                            </Box>
                            <Box margin='0' padding='0'textAlign='left'>
                                <FormControl isRequired as="form" onSubmit={(e)=>transferCredit(e)} display="flex" h="60%" justifyContent="center" w="100%">
                                    <Stack color="color5" width="200px">
                                        <Text fontWeight='bold'>Transferir Saldo</Text>
                                        <FormLabel htmlFor="fcredits" fontWeight='normal'>Saldo a transferir</FormLabel>
                                        <Input size='sm' id="fcredits" name="credits" value={context.state.formTransferCredit.credits} onChange={(e) => context.handleInput(e, 'formTransferCredit')} color="color5" type="number" rounded="2"  isRequired/>
                                        <FormLabel htmlFor="fuser" fontWeight='normal' justifySelf="left">Usuario a transferir</FormLabel>
                                        <Input size='sm' id="fuser" name="email" value={context.state.formTransferCredit.email} onChange={(e) => context.handleInput(e, 'formTransferCredit')} placeholder="correo@mail.com" color="color5" type="email" isRequired/>
                                        <Button isDisabled={loading} type="submit"size="sm" mt={5} width='80px' padding='10px' margin='0 auto' borderRadius='100px' backgroundColor='color3' color='color6'>
                                            Transferir
                                        </Button>
                                    </Stack>
                                    </FormControl>
                            </Box>
                        </Flex>
                    </Flex>
                    
                </Box>
            }        
       </>
)}

export default ProfileAdmin
