import React ,{useContext,useEffect} from 'react';
import {MyContext} from '../Context'
import {Icon,Text,Box,Accordion,AccordionItem, AccordionHeader, AccordionPanel, AccordionIcon, Link} from "@chakra-ui/core";

function FAQ() {
    const context=useContext(MyContext)
    useEffect(()=>{
        const idUser=localStorage.getItem('idUser')
    if (idUser && !context.state.isLogged && context.state.feed) {
        context.restoreData() 
    }
      })
    return (
        <Box display='flex' flexDirection='column' alignItems='center' color='color5'>
            <Text margin='20px' textAlign='center' fontWeight='bold' fontSize='4xl' >FAQs</Text>
            <Box w='100%' display='flex' flexDirection={{sm:'column',md:'row'}} justifyContent={{sm:'center',md:'space-between'}} alignItems={{sm:'center',md:'flex-start'}}>
                <Accordion ml={5} w={{sm:'80%',md:'60%'}} allowToggle textAlign="left">
                <AccordionItem>
                    <AccordionHeader>
                    <Box fontWeight='bold' flex="1" textAlign="left" >
                        ¿Cuál es el tiempo de entrega de mi pedido?
                    </Box>
                    <AccordionIcon />
                    </AccordionHeader>
                    <AccordionPanel pb={4}>
                        Al crear tu orden puedes ver las fechas disponibles para entrega y podrás seleccionar la que más te agrade. Este es un tiempo estimado, pero ten por seguro que haremos todo lo posible por tenerlo antes de esa fecha.
                        <br /> 
                        La fecha de entrega se actualizará en la ventana de pago hasta que realices el pago y se quedará registrada la última fecha que elijas. Una vez realizado el pago, podrás ver la fecha de entrega de tu orden en tu perfil. 
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionHeader>
                    <Box fontWeight='bold' flex="1" textAlign="left">
                        Quiero un parámetro de impresión diferente a las opciones disponibles
                    </Box>
                    <AccordionIcon />
                    </AccordionHeader>
                    <AccordionPanel pb={4}>
                        Si la opción no está disponible nos puedes poner tus especificaciones en la sección de notas de la pieza que se encuentra en el cotizador.
                        <br />
                        También nos puedes contactar a nuestro correo  (3dprint@emisha.com.mx) para que te asesoremos.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionHeader>
                    <Box fontWeight='bold' flex="1" textAlign="left">
                        ¿Cómo puedo modificar mi orden?
                    </Box>
                    <AccordionIcon />
                    </AccordionHeader>
                    <AccordionPanel pb={4}>
                        Si tu orden tiene estatus "Pago Pendiente" o "Recibida" puedes ingresar a tu perfil y cancelar tu orden para generar una nueva.  
                        <br />
                        Si tu orden tiene un estatus diferente, puedes contactarnos al correo (3dprint@emisha.com.mx) para ver cómo lo podemos resolver.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionHeader>
                    <Box fontWeight='bold' flex="1" textAlign="left">
                        ¿Qué pasa con mi pago si cancelo el pedido?
                    </Box>
                    <AccordionIcon />
                    </AccordionHeader>
                    <AccordionPanel pb={4}>
                        Si tu orden se encontraba en estatus "Pago Pendiente" o "Recibida" y habías realizado un pago total o parcial de tu orden, se te reembolsará el monto total de lo que pagaste como saldo disponible en tu perfil. Este saldo lo podrás usar para otras compras.
                        <br />
                        En cualquier otro caso, al comunicarte con nosotros para hacer la cancelación te indicaremos si es posible hacer algún tipo de reembolso y bajo que condiciones.
                    </AccordionPanel>
                </AccordionItem>

                </Accordion>
                <Box w={{sm:'80%',md:'30%'}} margin={{sm:'5% 0',md:'0 3%'}} textAlign='left' padding='10px 20px' border='solid 1px teal' borderRadius='20px'>
                    <Text fontWeight='bold' bottom='10px'>Contacto</Text>
                    <br />
                    <Text textAlign='justify'>Para cualquier duda o comentario nos puedes contactar directamente al correo: </Text>
                    <br />
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Icon mr={3} name="email" fontSize='20px' />
                        <Text>3dprint@emisha.com.mx</Text>
                    </Box>
                    <br />
                    <Text>Lunes a viernes</Text>
                    <Text>9:00 am a 6:00 pm</Text>
                    <br />
                    <br />
                    <Text>Conoce nuestro {" "}
                    <Link color='color5' href="https://emisha3dprint.com/avisoPrivacidad">
                        Aviso de Privacidad
                    </Link>
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default FAQ
