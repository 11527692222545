import React,{useContext,useEffect} from 'react'
import {MyContext} from '../Context'
import {useDisclosure,Flex,Text, Box,useToast,Divider, SimpleGrid,Tabs,Tab, TabList, TabPanels, TabPanel,Spinner,
    Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter, ModalBody, ModalCloseButton, NumberInput,NumberInputField,NumberDecrementStepper,NumberIncrementStepper,NumberInputStepper,Button  } from "@chakra-ui/core";
import LateralMenu from '../components/LateralMenu'
import { FaEdit,FaCut,FaTrashAlt,FaTruckMoving } from "react-icons/fa";

function ResumenOrdenesCorte({history}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [percentage,setPercentage]=React.useState(50.00)
    const context=useContext(MyContext)
    const [cancelId,setCancelId]=React.useState('');
    useEffect(()=>{
        if (!context.state.isLogged && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
                context.restoreData()
                .catch((err)=>history.push('/'))
                 
            }else{
                history.push('/')
            }
        }
      })
    const toast = useToast()
    const [loading, setLoading] = React.useState(false);
    const print=(idorden)=>{
        setLoading(true);
        context.printOrder(idorden)
            .then(resp=>{
                toast({
                    title: 'Mandar a Imprimir', 
                    description: resp.msg, 
                    status: 'success',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
            })
            .catch(err=>{
                toast({
                    title: 'Mandar a imprimir', 
                    description: 'No se pudo mandar a imprimir', 
                    status: 'error',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
            })
    }
    const getOrder=async (orden)=>{
        setLoading(true);
        await context.setOrden(orden,false)
        setLoading(false);
        history.push(`/detalle/${orden.numero}`)
    }
    const showCancel=(idorden)=>{
        setCancelId(idorden);
        onOpen();
    }
    const cancel=()=>{
        setLoading(true);
        onClose();
        context.cancelarOrder(cancelId,percentage)
            .then(resp=>{
                toast({
                    title: 'Cancelar Orden', 
                    description: resp.msg, 
                    status: 'success',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
                
            })
            .catch(err=>{
                toast({
                    title: 'Cancelar Orden', 
                    description: 'No se pudo cancelar', 
                    status: 'error',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
            })
    }
    return (
        <>
            {context.state.isLogged && context.state.isAdmin && 
                <Box display='flex' flexDir='row' justifyContent='space-between' alignItems='flex-start' w='100vw' color='color5'>
                    <LateralMenu width={'15vw'} />
                    <Flex flexDir='column' w='80vw' paddingRight='5vw'>
                        <Text textAlign='left' color='color5' fontWeight='bold' fontSize='2xl'>Corte Láser</Text>
                        <Divider border='solid 3px' color='color5' borderColor="color5" width='100%'/>
                        <Tabs size='md' variantColor='teal'>
                            <TabList>
                                <Tab>Recibidas</Tab>
                                <Tab>Cortando</Tab>
                                <Tab>Histórico</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={8} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center' gridColumn="2/4">Cliente</Text>
                                        <Text justifySelf='center'>Piezas</Text>
                                        <Text justifySelf='center'>Archivos</Text>
                                        <Text justifySelf='center'>Fecha envío</Text>
                                        <Text justifySelf='center'>Subtotal</Text>
                                        <Text justifySelf='center'>Acción</Text>
                                    </SimpleGrid>    
                                        {context.state.orders && 
                                            <>
                                                {!context.state.orders.corteRecibida.length ?
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="3/6" textAlign='center'>No hay ordenes</Text>
                                                    </SimpleGrid>
                                                    :
                                                    <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderColor='color5' borderTop='none' borderRadius='0px 0px 20px 20px'>
                                                        {context.state.orders.corteRecibida.map((orden,index)=>
                                                            <Box key={`CR_${index}`}>
                                                                <SimpleGrid  textAlign='center' columns={8} borderBottom='solid 1px' color='color3' borderColor='color5'>
                                                                    <Text textAlign='center'>{orden.numero}</Text>
                                                                    <Text textAlign='center' gridColumn="2/4">{orden.cliente.name} : {orden.cliente.email}</Text>
                                                                    <Text textAlign='center'>{orden.totalPiezas}</Text>
                                                                    <Text textAlign='center'>{orden.archivos.length}</Text>
                                                                    <Text textAlign='center'>{orden.fechaEnvio.split(',')[1]}</Text>
                                                                    <Text textAlign='center'>${orden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                                                                    <Flex justifyContent='space-around' alignItems='center'>
                                                                        <Box className='boton' as={FaEdit} onClick={e=>getOrder(orden)}/>  
                                                                        <Box className='boton' as={FaCut} onClick={e=>print(orden._id)}/> 
                                                                        <Box className='boton' as={FaTrashAlt}  onClick={e=>showCancel(orden._id)}/> 
                                                                    </Flex>
                                                                </SimpleGrid>
                                                                <Modal preserveScrollBarGap isCentered={true} isOpen={isOpen} onClose={onClose}>
                                                                    <ModalOverlay />
                                                                    <ModalContent>
                                                                    <ModalHeader>Cancelar Orden</ModalHeader>
                                                                    <ModalCloseButton />
                                                                    <ModalBody display='flex' flexDirection='column' justifyContent='space-around' alignItems='center'>
                                                                        ¿Qué porcentaje deseas regresar de la orden?
                                                                        <NumberInput width='100px' size='sm' onChange={e=>setPercentage(e)} value={percentage} precision={2} step={0.5} max={100} min={0}>
                                                                            <NumberInputField />
                                                                            <NumberInputStepper>
                                                                                <NumberIncrementStepper />
                                                                                <NumberDecrementStepper />
                                                                            </NumberInputStepper>
                                                                        </NumberInput>
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <Button variant="ghost" size="sm" borderRadius='100px' width='150px' margin='5px 0' color='color3' mr={3} onClick={onClose}>Cancelar</Button>
                                                                        <Button isDisabled={loading} size="sm" borderRadius='100px' width='150px' margin='5px 0' backgroundColor='color3' color='color6' onClick={e=>cancel()}>Procesar</Button>
                                                                    </ModalFooter>
                                                                    </ModalContent>
                                                                </Modal>
                                                            </Box>  
                                                        )}
                                                    </Box>
                                                }
                                            </>
                                        }
                                </TabPanel>
                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={8} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center' gridColumn="2/4">Cliente</Text>
                                        <Text justifySelf='center'>Piezas</Text>
                                        <Text justifySelf='center'>Archivos</Text>
                                        <Text justifySelf='center'>Fecha envío</Text>
                                        <Text justifySelf='center'>Subtotal</Text>
                                        <Text justifySelf='center'>Acción</Text>
                                    </SimpleGrid>    
                                        {context.state.orders && 
                                            <>
                                                {!context.state.orders.corteImprimiendo.length ?
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="4/6"  textAlign='center'>No hay ordenes</Text>
                                                    </SimpleGrid>
                                                    :
                                                    <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderTop='none' borderColor='color5'  borderRadius='0px 0px 20px 20px'>
                                                        {context.state.orders.corteImprimiendo.map((orden,index)=>
                                                            <SimpleGrid key={`CI_${index}`} textAlign='center' columns={8} borderBottom='solid 1px' color='color3' borderColor='color5'>
                                                                <Text textAlign='center'>{orden.numero}</Text>
                                                                <Text textAlign='center' gridColumn="2/4">{orden.cliente.name} : {orden.cliente.email}</Text>
                                                                <Text textAlign='center'>{orden.totalPiezas}</Text>
                                                                <Text textAlign='center'>{orden.archivos.length}</Text>
                                                                <Text textAlign='center'>{orden.fechaEnvio.split(',')[1]}</Text>
                                                                <Text textAlign='center'>${orden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                                                                <Flex justifyContent='space-around' alignItems='center'>
                                                                    <Box className='boton' as={FaEdit} onClick={e=>getOrder(orden)}/> 
                                                                    <Box className='boton' as={FaTruckMoving}/>
                                                                </Flex>
                                                            </SimpleGrid>  
                                                        )}
                                                    </Box>       
                                                }
                                            </>
                                        }
                                </TabPanel>
                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={9} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center' gridColumn="2/4">Cliente</Text>
                                        <Text justifySelf='center'>Piezas</Text>
                                        <Text justifySelf='center'>Archivos</Text>
                                        <Text justifySelf='center'>Fecha envío</Text>
                                        <Text justifySelf='center'>Subtotal</Text>
                                        <Text justifySelf='center'>Estatus</Text>
                                        <Text justifySelf='center'>Acción</Text>
                                    </SimpleGrid>    
                                        
                                        {context.state.orders && context.state.orders.corte.length && 
                                            <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderColor='color5'  borderTop='none' borderRadius='0px 0px 20px 20px'>
                                                {context.state.orders.corte.length &&context.state.orders.corte.map((orden,index)=>
                                                    <SimpleGrid key={`CT_${index}`} textAlign='center' columns={9} borderBottom='solid 1px' color='color3' borderColor='color5'>
                                                        <Text textAlign='center'>{orden.numero}</Text>
                                                        <Text textAlign='center' gridColumn="2/4">{orden.cliente.name} : {orden.cliente.email}</Text>
                                                        <Text textAlign='center'>{orden.totalPiezas}</Text>
                                                        <Text textAlign='center'>{orden.archivos.length}</Text>
                                                        <Text textAlign='center'>{orden.fechaEnvio.split(',')[1]}</Text>
                                                        <Text textAlign='center'>${orden.subTotal}</Text>
                                                        <Text textAlign='center'>{orden.estatus}</Text>
                                                        <Flex justifyContent='space-around' alignItems='center'>
                                                            <Box className='boton' as={FaEdit} onClick={e=>getOrder(orden)}/> 
                                                        </Flex>
                                                    </SimpleGrid>  
                                                )}
                                                {context.state.orders && !context.state.orders.corte.length &&
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="4/6"  textAlign='center'>No hay ordenes</Text>
                                                    </SimpleGrid>
                                                }
                                            </Box>       
                                        }
                                </TabPanel>
                            
                            </TabPanels>
                        </Tabs>
                    </Flex>
                    
                </Box>
            }
            {loading && 
                <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
                    <Text color='color1' fontWeight='bold' fontSize='5xl'>Procesando</Text>
                    <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="teal.700"
                    size="xl"
                    />
                </Box>
            }       
       </>
)}

export default ResumenOrdenesCorte
