import React ,{useContext} from 'react'
import {
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent,
    DrawerCloseButton, Tabs, TabList, TabPanels, Tab, TabPanel, Button, useDisclosure, useToast,
    Stack,FormControl,Input,FormLabel ,Text
  } from "@chakra-ui/core";
import { MyContext } from '../Context'

function Auth(props) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const btnRef = React.useRef();
  const toast = useToast()
  const context = useContext(MyContext)

  const handleTabsChange = index => {
    setTabIndex(index);
  };
  const submitLogin = async (e) => {
    const { user, msg } = await context.handleLoginSubmit(e)
    if (user){
      localStorage.setItem('idUser',user._id)
    }
    else {
      toast({
        title: 'Revisa tus credenciales.',
        description: msg,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position:'top-right'
      })
    } 
  }
  const resetPassword = async (e) => {
    const { msg } = await context.resetPassword(e)
      toast({
        title: 'Cambio de contraseña',
        description: msg,
        duration: 5000,
        isClosable: true,
        position:'top-right'
      }) 
  }
  const submitSignup = async (e) => {
      const {user, msg } = await context.handleSignupSubmit(e)
      if (user) {
          toast({
          title: "Usuario creado",
          description: msg,
          status: "success",
          duration: 4000,
          isClosable: true,
          position:'top-right'
        })
        
        setTabIndex(0)
      } else {

        toast({
          title: 'Usuario no creado.', 
          description: msg, 
          status: 'error',
          duration: 5000, 
          isClosable: true,
          position:'top-right'
        })
      }
    }
  return (
    <MyContext.Consumer>
    {context => {
      return (
      <>
        <Text ref={btnRef}  onClick={onOpen}
        {...props} >
          {props.value}
        </Text>
        <Drawer
          isOpen={isOpen}
          placement="right"
          initialFocusRef={firstField}
          finalFocusRef={btnRef}
          onClose={onClose}
          size={{ sm: "full", md: "md" }}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader color='color5' borderBottomWidth="1px">
              Cuenta
            </DrawerHeader>

            <DrawerBody>
                <Tabs  color='color5' index={tabIndex}  isFitted variant="enclosed" onChange={handleTabsChange}>
                    <TabList>
                      <Tab>Ingresar a tu Cuenta</Tab>
                      <Tab>Usuario Nuevo</Tab>
                      <Tab>Olvidé mi Contraseña</Tab>
                    </TabList>
                
                    <TabPanels>
                      <TabPanel>
                        <FormControl isRequired as="form" onSubmit={submitLogin} display="flex" h="60%" justifyContent="center" w="100%" p={4} color="red">
                          <Stack color="color5" width="40%" minWidth="300px">
                              <FormLabel fontSize='sm' htmlFor="femail1">Email</FormLabel>
                              <Input ref={firstField} id='femail1' size='sm' name="email" value={context.state.formLogin.email} onChange={(e) => context.handleInput(e, 'formLogin')} color="color5" type="email" roundedLeft="0" placeholder="correo@mail.com" isRequired/>
                              <FormLabel fontSize='sm' htmlFor="fpass2">Contraseña</FormLabel>
                              <Input size='sm' id="fpass2" name="password" value={context.state.formLogin.password} onChange={(e) => context.handleInput(e, 'formLogin')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                              <br />
                              <Button type="submit" borderRadius='100px' minWidth="150px" alignSelf="center" w="30%" backgroundColor="color3" color="color6" size="sm">
                                  Iniciar sesión
                              </Button>
                          </Stack>
                        </FormControl>  
                      </TabPanel>
                      <TabPanel>
                        <FormControl isRequired as="form" onSubmit={submitSignup} display="flex" h="60%" justifyContent="center" w="100%" p={4} color="red">
                          <Stack color="color5" width="40%" minWidth="300px">
                              <FormLabel fontSize='sm' htmlFor="fname">Nombre</FormLabel>
                              <Input size='sm' id="fname" name="name" value={context.state.formSignup.name} onChange={(e) => context.handleInput(e, 'formSignup')} type="text" roundedLeft="0" isRequired />
                              <FormLabel fontSize='sm' htmlFor="fphone">Teléfono de contacto (10 dígitos)</FormLabel>
                              <Input size='sm' id="fphone" name="phone" value={context.state.formSignup.phone} onChange={(e) => context.handleInput(e, 'formSignup')} type="text" placeholder='5512345678' pattern="[0-9]{2}[ -]?[0-9]{2}[ -]?[0-9]{2}[ -]?[0-9]{2}[ -]?[0-9]{2}" isRequired />
                              <FormLabel fontSize='sm' htmlFor="femail">Email</FormLabel>
                              <Input size='sm' id='femail' name="email" value={context.state.formSignup.email} onChange={(e) => context.handleInput(e, 'formSignup')} color="color5" type="email" roundedLeft="0" placeholder="correo@mail.com" isRequired/>
                              <FormLabel fontSize='sm' htmlFor="femailconf">Confirmar correo</FormLabel>
                              <Input size='sm' id="femailconf" name="emailConfirm" value={context.state.formSignup.emailConfirm} onChange={(e) => context.handleInput(e, 'formSignup')} color="color5" type="email" roundedLeft="0" placeholder="correo@mail.com" isRequired/>
                              <FormLabel fontSize='sm' htmlFor="fpass">Contraseña</FormLabel>
                              <Input size='sm' id="fpass" name="password" value={context.state.formSignup.password} onChange={(e) => context.handleInput(e, 'formSignup')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                              <FormLabel fontSize='sm' htmlFor="fpassconf" justifySelf="left">Confirmar contraseña</FormLabel>
                              <Input size='sm' id="fpassconf" name="passwordConfirm" value={context.state.formSignup.passwordConfirm} onChange={(e) => context.handleInput(e, 'formSignup')} color="color5" type="password" rounded="0" placeholder="********" isRequired/>
                              <Button type="submit" borderRadius='100px' minWidth="150px" alignSelf="center" w="30%" backgroundColor="color3" color="color6" size="sm">
                                  Crear cuenta
                              </Button>
                          </Stack>
                        </FormControl>
                      </TabPanel>
                      <TabPanel>
                        <FormControl isRequired as="form" onSubmit={resetPassword} display="flex" h="60%" justifyContent="center" w="100%" p={4} color="red">
                          <Stack color="color5" width="40%" minWidth="300px">
                              <FormLabel fontSize='sm' htmlFor="femail2">Email</FormLabel>
                              <Input ref={firstField} id='femail2' size='sm' name="email" value={context.state.formLogin.email} onChange={(e) => context.handleInput(e, 'formLogin')} color="color5" type="email" roundedLeft="0" placeholder="correo@mail.com" isRequired/>
                               <br />
                              <Button type="submit" borderRadius='100px' minWidth="150px" alignSelf="center" w="30%" backgroundColor="color3" color="color6" size="sm">
                                  Solicitar
                              </Button>
                          </Stack>
                        </FormControl>  
                      </TabPanel>
                    </TabPanels>
                </Tabs>
            </DrawerBody>

          </DrawerContent>
        </Drawer>
      </>);
    }}
    </MyContext.Consumer>
  );
}

  export default Auth
