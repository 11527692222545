import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import STLLoaderModule from 'three-stl-loader';
import OrbitControlsModule from 'three-orbit-controls';
import {ScaleLoader} from 'react-spinners';
import {Box} from '@chakra-ui/core';
import {MyContext} from '../../Context';

const STLLoader = STLLoaderModule(THREE);
const OrbitControls = OrbitControlsModule(THREE);

let xDims, yDims, zDims; 


const setTotal=(maxIndex,index,cost,amount)=>{
    let total=cost*amount;
    if (document.getElementById(`cost_${index}`))
        document.getElementById(`cost_${index}`).innerHTML=cost.toLocaleString('es-MX');
    if(document.getElementById(`subTotal_${index}`))
        document.getElementById(`subTotal_${index}`).innerHTML=(cost*amount).toLocaleString('es-MX');
    for (let i=0;i<=maxIndex;i++){
        if (i!==index && document.getElementById(`subTotal_${i}`)){
            total+=(document.getElementById(`subTotal_${i}`).innerHTML.split(',').join('')*1);
        }
    }
    if (document.getElementById(`Total`))
        document.getElementById(`Total`).innerHTML=total.toLocaleString('es-MX');
};

const getVolume=(geometry)=> {

    let position = geometry.attributes.position;
    let faces = position.count / 3;
    let sum = 0;
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0);
      p2.fromBufferAttribute(position, i * 3 + 1);
      p3.fromBufferAttribute(position, i * 3 + 2);
      sum += p1.dot(p2.cross(p3));
    }
    return {vol:sum/6.0,area:sum,triangles:faces};
  
};
const getCost=(dims,costs,units,x,y,z,amount,escala,soporte)=>{
    let cost=0;
    const {vol}=dims;
    const voltotal=x*y*z;
    //console.log('costos',costs,'dimensiones',dims)
    const {fill,quality,material,color}=costs;
    if (units==='mm'){
        cost = ((vol*(1-((1-fill)*(Math.max(vol/voltotal,0.06)))))*(1*material)/(quality*2.88))*(1.815/60); // se calcula que la velocidad de impresion es 60
    } else {
        cost =(((vol*25.4*25.4*25.4)*(1-((1-fill)*(Math.max(vol/voltotal,0.06)))))*(1*material)/(quality*2.88))*(1.815/60);
    }
    cost*=(escala/100);
    if (soporte)
        cost*=1.6;
    cost += (color/amount);
    cost=Math.max(Math.round(15/amount*100)/100,cost);
    return Math.round(cost*100)/100;
};
const setAlert=(x,y,z,units,index)=>{
    if (document.getElementById(`warn_${index}`)){
        document.getElementById(`warn_${index}`).innerHTML='';
        if(units==='mm' && (x/10>38 || y/10>38 || z/10>40)){
            document.getElementById(`warn_${index}`).innerHTML='Nota: Tu pieza tiene que imprimirse en partes.';
        }
        else if (units==='in' && (x*2.54>38 || y*2.54>38 || z*2.54>40)) {
            document.getElementById(`warn_${index}`).innerHTML='Nota: Tu pieza tiene que imprimirse en partes.';
        }
    }
}
export class STLViewer extends Component {
    state={
        x:0,
        y:0,
        z:0,
        vol:0,
        load:true,
        units:'',
        name:'',
        cost:0,
        index:0,
        color:'',
        file:null,
        fill:'',
        quality:'',
        material:'',
        amount:1,
        escala:100,
        soporte:false
    };
    static propTypes = {
        className: PropTypes.string,
        url: PropTypes.string,
        file: PropTypes.object,
        width: PropTypes.number,
        height: PropTypes.number,
        backgroundColor: PropTypes.string,
        modelColor: PropTypes.string,
        sceneClassName: PropTypes.string,
        onSceneRendered: PropTypes.func,
        units:PropTypes.string,
        index:PropTypes.number,
        maxIndex:PropTypes.number,
        amount:PropTypes.number,
        material:PropTypes.string,
        quality:PropTypes.string,
        fill:PropTypes.string,
        escala:PropTypes.number,
        soporte:PropTypes.bool
    };

    static defaultProps = {
        backgroundColor: '#EAEAEA',
        modelColor: '#B92C2C',
        height: 350,
        width: 350,
        rotate: true,
        orbitControls: true,
        sceneClassName: '',
    };
    setDimensions=(index,escala)=>{
        if (this.context.state.formArchivos[index].nuevo)
            this.context.setDimensions(Math.round(xDims*escala)/100,Math.round(yDims*escala)/100,Math.round(zDims*escala)/100,index);

        if (document.getElementById(`Dims_${index}`) ){
            document.getElementById(`Dims_${index}`).innerHTML=`${Math.round(xDims*escala)/100} x ${Math.round(yDims*escala)/100} x ${Math.round(zDims*escala)/100}`
            document.getElementById(`DimX_${index}`).innerHTML=Math.round(xDims*100)/100
            document.getElementById(`DimY_${index}`).innerHTML=Math.round(yDims*100)/100
            document.getElementById(`DimZ_${index}`).innerHTML=Math.round(zDims*100)/100
        }
    }

    componentDidMount() {
        this.renderModel(this.props);
    }
    
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    renderModel(props) {
        let camera, scene, renderer, mesh, distance, controls;
        const {url, file, width, height, modelColor, backgroundColor, orbitControls, 
            sceneClassName, onSceneRendered, index, maxIndex, amount,costs} = props;
        
        let component = this;


        scene = new THREE.Scene();
        distance = 10000;
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.x = 0;
        directionalLight.position.y = .5;
        directionalLight.position.z = .5;
        directionalLight.position.normalize();
        scene.add(directionalLight);

        const ambientLight = new THREE.AmbientLight(0x404040); // soft white light
        scene.add(ambientLight);

        const onLoad = geometry => {
            geometry.computeFaceNormals();
            geometry.computeVertexNormals();
            geometry.center();

            mesh = new THREE.Mesh(
                geometry,
                new THREE.MeshLambertMaterial({
                        //overdraw: true,
                        color: modelColor,
                    }
                ));

            geometry.computeBoundingBox();
            xDims = geometry.boundingBox.max.x - geometry.boundingBox.min.x;
            yDims = geometry.boundingBox.max.y - geometry.boundingBox.min.y;
            zDims = geometry.boundingBox.max.z - geometry.boundingBox.min.z;

            scene.add(mesh);
            
            const dims=getVolume(geometry)
            const nombre=this.props.file?this.props.file.name:'';
            this.setState({x:Math.floor(xDims*100)/100,
                            y:Math.floor(yDims*100)/100,
                            z:Math.floor(zDims*100)/100,
                            vol:Math.floor(dims.vol*100)/100,
                            area:Math.floor(dims.area*100)/100,
                            triangles:Math.floor(dims.triangles*100)/100,
                            load:true,
                            units:this.props.units,
                            color:this.props.modelColor,
                            name:nombre,
                            index:index,
                            material:this.props.material*1,
                            quality:this.props.quality*1,
                            fill:this.props.fill*1,
                            amount:this.props.amount*1,
                            escala:this.props.escala,
                            soporte:this.props.soporte
                        })
            this.setState({cost:getCost(dims,costs,this.state.units,xDims,yDims,zDims,amount,this.state.escala,this.state.soporte)})
            //console.log('dimensions:'+ xDims + 'x' + yDims + 'x' + zDims)
            //console.log(this.state)
            //console.log("stl volume is " +dims.vol);
            //console.log("stl area is " +dims.area);
            //console.log("stl triangles is " +dims.triangles);
            
            setTotal(maxIndex,index,this.state.cost,amount)
            setAlert(xDims,yDims,zDims,this.props.units,index)

            camera = new THREE.PerspectiveCamera(30, width / height, 1, distance);
            camera.position.set(0, 0, Math.max(xDims * 3, yDims * 3, zDims * 3));

            scene.add(camera);

            renderer = new THREE.WebGLRenderer({
                preserveDrawingBuffer: true,
                antialias: true
            });
            renderer.setSize(width, height);
            renderer.setClearColor(backgroundColor, 1);
            renderer.domElement.className = sceneClassName;


            if (orbitControls && this.updater.isMounted(component)) {
                controls = new OrbitControls(camera, ReactDOM.findDOMNode(component));
                controls.enableKeys = false;
                controls.addEventListener('change', orbitRender);
            }
            if (this.updater.isMounted(component)){
            ReactDOM.findDOMNode(this).replaceChild(renderer.domElement,
                ReactDOM.findDOMNode(this).firstChild);
            }
            render();

            if (typeof onSceneRendered === "function") {
                onSceneRendered(ReactDOM.findDOMNode(renderer.domElement))
            }
            this.setDimensions(index,this.props.escala)
        };

        const onProgress = (xhr) => {
            if (xhr.lengthComputable) {
                let percentComplete = xhr.loaded / xhr.total * 100;
                console.log(percentComplete)
            }
        };

        const loader = new STLLoader();

        if (file) {
            loader.loadFile(file, onLoad, onProgress);
        } else {
            loader.load(url, onLoad, onProgress);
        }

        const render = () => {
            renderer.render(scene, camera);
        };

        const orbitRender = () => {
            render();
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate(nextProps, nextState) {
        this.renderModel(nextProps);
    }

    componentDidCatch(error, info) {
        console.log(error, info)
    }

    render() {
        return (
            <Box display='flex' flexDirection='row' justifyContent='space-around'>
                <div
                    className={this.props.className}
                    style={{
                        width: this.props.width,
                        height: this.props.height,
                        overflow: 'hidden',
                    }}
                >
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <ScaleLoader
                            color={'#123abc'}
                            loading={true}
                        />
                    </div>
                </div>
            </Box>

        );
    };
};

STLViewer.contextType = MyContext

export default STLViewer