import React ,{useContext,useEffect} from 'react';
import {MyContext} from '../Context'
import {Box,Button, Text, Divider,Icon} from "@chakra-ui/core";

function Home({history}) {
    const context=useContext(MyContext)
    const go = path => history.push(path)
    useEffect(()=>{
        const idUser=localStorage.getItem('idUser')
    if (idUser && !context.state.isLogged && context.state.feed) {
        context.restoreData()
    }
      })
    return (
        <Box w='100%' color='color5'>
            <Box display='flex' justifyContent={{sm:"left",md:"center"}} alignItems='flex-start' mt={{sm:'15px',md:'0'}}
            backgroundPosition='center right' backgroundRepeat='no-repeat' backgroundSize='contain' 
            backgroundImage={{sm:`url("./Impresion3dMexico_mov_1.png")`,md:`url("./Impresion3dMexico_wide.png")`}} 
            title='CotizaEnLinea'
            h={{sm:'50vw',lg:'80vh'}} w='100vw' > 
                <Box textAlign='left' margin={{sm:'0 30px',md:'0 50px'}} display='flex' flexDirection='column' justifyContent='center'  height='100%'>
                    <Text fontWeight='bold' fontSize={{sm:'lg',md:'xl'}}>Impresión 3D</Text>
                    <Text fontWeight='bold' fontSize={{sm:'lg',md:'xl'}}>en línea</Text>
                    <Divider border={{sm:'solid 1px',md:'solid 2px'}}  borderColor="color5" backgroundColor="color5" width='100%'/>
                    <Text>Cotiza en tiempo real tu</Text>
                    <Text>producto o diseño</Text>
                    <Button size="lg" alignSelf='center' mt='20px'  borderRadius='100px' width='150px' backgroundColor='color3' color='color6'  onClick={()=>history.push("/cotizador")}>Cotizar</Button>
                </Box>
            </Box>
            <Box>
                <Text fontWeight='bold' fontSize={{sm:'lg',md:'xl'}}>Nuestros Servicios</Text>
                <Divider margin={{sm:'0px 30px',md:'0px 50px'}} border={{sm:'solid 1px',md:'solid 2px'}} backgroundColor="color5" alignSelf='center' borderColor="color5"  w={{sm:'85%',md:'90%'}}/>
                    
                <Box display='flex' mt={{sm:'30px',md:'0'}}  flexDirection={{sm:'column',md:'row'}} justifyContent='space-around' w='100vw' textAlign='center'>
                    <Box className="boton" color='color5'w={{sm:'100vw',md:'45vw'}} h={{sm:'50vw',md:'25vw'}} 
                    display='flex' flexDirection='column' justifyContent='center' alignItems='center' >
                        <Icon name="impresion" size="15vw"  onClick={() =>go('/cotizador')}></Icon> 
                        <Text  onClick={() =>go('/cotizador')} >Impresión 3D</Text>
                    </Box>
                    <Box className="boton" color='color1'w={{sm:'100vw',md:'45vw'}} h={{sm:'50vw',md:'25vw'}} 
                    display='flex' flexDirection='column' justifyContent='center' alignItems='center' >
                        <Icon name="laser" size="15vw"></Icon> 
                        <Text >Corte Láser</Text>
                        <Text >Próximamente</Text>
                        {/*
                        <Icon name="laser" size="15vw" onClick={() =>go('/cotizadorCorte')}></Icon> 
                        <Text onClick={() =>go('/cotizadorCorte')}>Corte Láser</Text>
                        */}
                    </Box>
                    <Box className="boton" color='color1'w={{sm:'100vw',md:'45vw'}} h={{sm:'50vw',md:'25vw'}} 
                    display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <Icon name="cnc" size="15vw" ></Icon> 
                        <Text >CNC</Text>
                        <Text >Próximamente</Text>
                    </Box>
                </Box>
            </Box>

            <Box>
                <Text fontWeight='bold' fontSize={{sm:'lg',md:'xl'}}>Impresión 3D</Text>
                <Divider margin={{sm:'0px 30px',md:'0px 50px'}} border={{sm:'solid 1px',md:'solid 2px'}} alignSelf='center' borderColor="color5" backgroundColor="color5"  w={{sm:'85%',md:'90%'}}/>
                
                <Box display='flex' mt={{sm:'30px',md:'0'}}  flexDirection={{sm:'column',md:'row'}} justifyContent='space-around' w='100vw'>
                    <Box display='flex' justifyContent={{sm:'flex-end',md:'center'}} color='color5'w={{sm:'100vw',md:'45vw'}} h={{sm:'50vw',md:'25vw'}} 
                    backgroundPosition={{sm:'center',md:'right'}} backgroundRepeat='no-repeat' title='hasta40x40'
                    backgroundSize='contain' backgroundImage={{sm:`url("./Impresion3dMexico_mov_2.png")`,md:`url("./Impresiones3d_WEB_1.png")`}} >
        
                        <Box fontWeight='bold' fontSize={{sm:'lg',md:'xl'}} alignSelf='flex-start' margin={{sm:'10px 30px',md:'30px 50px'}} >
                            <Text>Imprime hasta</Text>
                            <Text>de 40x40cm</Text>
                            <Divider margin='3px' border={{sm:'solid 1px',md:'solid 2px'}}  borderColor="color5" backgroundColor="color5" width='100%'/>
                        </Box>
                    
                    </Box>
                    <Box display={{sm:'none',md:'flex'}} justifyContent='flex-start' color='color5'w={{sm:'0vw',md:'45vw'}} h={{sm:'0vw',md:'25vw'}} 
                    backgroundPosition='right' backgroundRepeat='no-repeat' backgroundSize='contain' 
                    title='soporteSoluble' backgroundImage={{md:`url("./Impresiones3d_WEB_2.png")`}} >
        
                        <Box fontWeight='bold' textAlign='left' fontSize={{sm:'lg',md:'xl'}} alignSelf='flex-start' margin={{md:'30px 20px'}} >
                            <Text>Soporte soluble</Text>
                            <Text>en agua</Text>
                            <Divider margin='3px' border={{sm:'solid 1px',md:'solid 2px'}}  borderColor="color5" backgroundColor="color5" width='50%'/>
                        </Box>
                    
                    </Box>
                    <Box color='color5'w={{sm:'100vw',md:'45vw'}} h={{sm:'50vw',md:'25vw'}} 
                    display='flex' flexDirection='column' justifyContent='flex-start' title='dobleExtrusor'
                    backgroundPosition='center left' backgroundRepeat='no-repeat' backgroundSize='contain'
                    backgroundImage={{sm:`url("./Impresion3dMexico_mov_3.png")`,md:`url("./Impresiones3d_WEB_3.png")`}} >
                        <Text  margin={{sm:'20px 30px',md:'30px 50px'}} mb={{sm:'3px',md:'10px'}} alignSelf='flex-start' fontWeight='bold' fontSize={{sm:'lg',md:'xl'}}>Doble extrusor</Text>
                        <Divider margin={{sm:'0px 30px',md:'0px 50px'}} border={{sm:'solid 1px',md:'solid 2px'}} alignSelf='flex-start' borderColor="color5" backgroundColor="color5" w={{sm:'85%',md:'40%'}}/>
                        <Box margin={{sm:'10px 30px',md:'10px 50px'}} alignSelf={{sm:'flex-end',md:'flex-start'}} fontSize='lg' textAlign='justify' >
                            
                            <Text>Podemos imprimir</Text>
                            <Text>tu producto con dos</Text>
                            <Text>materiales diferentes.</Text>
                            
                        </Box>
                    
                    </Box>
                </Box>
            </Box>
        </Box>            
    )
}

export default Home
