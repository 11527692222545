import React from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Text,Icon,ListItem} from '@chakra-ui/core';

function LateralMenu({ history,width }) {
  const go = path => history.push(path)

  const [showI, setShowI] = React.useState(false);
  const [showL, setShowL] = React.useState(false);

  return (
<>
          <Flex pos='fixed' bottom={0}  zIndex={98} h='100vh'direction='column'   paddingTop='15vh' backgroundColor='color7' color='color5' w={width}>
            <Flex className='menuItems2' justifyContent="center" flexDirection='column' alignItems='center' onClick={() =>go('/perfilAdmin')}>
              <Icon name="general" size="50px"></Icon> 
              <Text   onClick={() =>go('/perfilAdmin')}>Resumen</Text>
            </Flex>
            <Flex className='menuItems2'  justifyContent="center" flexDirection='column' alignItems='center' >
              <Icon name="impresion" size="50px" onClick={() =>setShowI(!showI)}></Icon> 
              <Text onClick={() =>setShowI(!showI)}>Impresión 3D</Text>
            </Flex>
            {showI && 
            <Flex direction='column' fontSize='xs' paddingLeft='20px'>
              <ListItem fontSize='sm' className='menuItems2' onClick={() =>go('/resumenOrdenes')}>Ordenes</ListItem>
              <ListItem fontSize='sm' className='menuItems2' onClick={() =>go('/resumenArchivos')}>Archivos</ListItem>
              <ListItem fontSize='sm' className='menuItems2' >Máquinas</ListItem>
            </Flex>}
            <Flex className='menuItems2' justifyContent="center" flexDirection='column' alignItems='center'>
              <Icon name="laser" size="50px" onClick={() =>setShowL(!showL)} ></Icon> 
              <Text onClick={() =>setShowL(!showL)} >Corte Láser</Text>
            </Flex>
            {showL && 
            <Flex direction='column' fontSize='xs' paddingLeft='20px'>
              <ListItem fontSize='sm' className='menuItems2'  onClick={() =>go('/resumenOrdenesCorte')}>Ordenes</ListItem>
              <ListItem fontSize='sm' className='menuItems2' >Archivos</ListItem>
              <ListItem fontSize='sm' className='menuItems2' >Máquinas</ListItem>
            </Flex>}
            <Flex className='menuItems2' justifyContent="center" flexDirection='column' alignItems='center'>
              <Icon name="cnc" size="50px"></Icon> 
              <Text>CNC</Text>
            </Flex>
            <Flex className='menuItems2' justifyContent="center" flexDirection='column' alignItems='center'>
              <Icon name="ajustes" size="50px"></Icon> 
              <Text>Ajustes</Text>
            </Flex>
            
            
          </Flex>
  <Flex w={width} ></Flex>
  </>          

  )
}

export default withRouter(LateralMenu)