import React, {createContext, Component} from 'react'
import {withRouter} from 'react-router-dom'
import SERVICE from './services/index'
  
export const MyContext = createContext()
  
class Context extends Component {
    state = {
      formSignup: {
        name: '',
        phone:'',
        email:'',
        emailConfirm: '',
        password: '',
        passwordConfirm:''
      },
      formLogin: {
        email: '',
        password: ''
      },
      formArchivos:[{
        escala:100,
        nuevo:true,
        soporte:false,
        fileURL:'',
        file:null,
        dimX:1,
        dimY:1,
        dimZ:1,
        dimX0:1,
        dimY0:1,
        dimZ0:1,
        name:'',
        units:'mm',
        fill:.3,
        color:'Blanco',
        colorHEX:'#FFFFFF',
        fileType:'',
        amount:1,
        quality:.3,
        comments:'',
        material:1,
        cost:0,
        costs:{
          material:0,
          quality:0,
          fill:0,
          color:0
        },
        index:{
          material:0,
          quality:2,
          fill:1,
          color:0
        },
        flag:false
      }],
      formOrden:{
        _id:'',
        archivos:[],
        fechaEnvio:'',
        subTotal:0,
        cargosAdicionales:0,
        envio:0,
        total:0,
        totalPagado:0,
        cliente:'',
        direccion:{},
        descripcion:'',
        infoPago:null,
        pagoPendiente:true,
        factura:false,
        tipoEnvio:'',
        tiposEnvio:[],
      },
      formArchivosCorte:[{
        escala:100,
        fileURL:'',
        file:null,
        perimetro:0,
        dimX:1,
        dimY:1,
        dimX0:1,
        dimY0:1,
        name:'',
        units:'cm',
        unitsPrecio:'cm',
        grosor:'',
        color:'',
        amount:1,
        comments:'',
        material:'',
        cost:0,
        costs:{
          material:0,
          corte:0
        },
        index:{
          material:0,
          color:0,
          grosor:0
        },
        flag:false
      }],
      totalCorte:0,
      formDireccion:{
        calle: '',
        ext:'',
        int:'',
        cp:'',
        delegacion:'',
        estado:'',
        colonia:'',
        info:null
      },
      formChangePass:{
        password:'',
        passwordConfirm:'',
      },
      formTransferCredit: {
        email: '',
        credits: ''
      },
      loggedUser: null,
      isLogged: false,
      perfil:null,
      isAdmin:false,
      materials:null,
      materialsCorte:null,
      feed:false,
      orders:null
    }
    async componentDidMount() {
      if (!this.state.feed) {
      const {data} = await SERVICE.materials()
      const {data:data2} = await SERVICE.materialsCorte()
      const indices=data.materials[0].indicesDefault;
      const indices2=data2.materials[0].indicesDefault;
      this.setState({
        feed:true,
        materials: data.materials,
        materialsCorte:data2.materials,
        formArchivos:[{...this.state.formArchivos[0],
          fill:data.materials[0].fill[indices.fill].name,
          color:data.materials[0].color[indices.color].name,
          colorHEX:data.materials[0].color[indices.color].value,
          quality:data.materials[0].quality[indices.quality].name.substring(0,6),
          material:data.materials[0].name,
          costs:{
            material:data.materials[0].value,
            quality:data.materials[0].quality[indices.quality].value,
            fill:data.materials[0].fill[indices.fill].value,
            color:data.materials[0].color[indices.color].cost
          },
          index:{
            material:0,
            quality:indices.quality,
            fill:indices.fill,
            color:indices.color
          }
        }],
        formArchivosCorte:[{...this.state.formArchivosCorte[0],
          material:data2.materials[0].name,
          color:data2.materials[0].color[indices2.color].name,
          grosor:data2.materials[0].color[indices2.color].grosor[indices2.grosor[indices2.color]].name,
          costs:{
            material:data2.materials[0].color[indices2.color].grosor[indices2.grosor[indices2.color]].costo,
            corte:data2.materials[0].color[indices2.color].grosor[indices2.grosor[indices2.color]].corte
          },
          index:{
            material:0,
            color:indices2.color,
            grosor:indices2.grosor
          },
        }]
      })
      }
    }
    componentWillUnmount(){
      localStorage.removeItem('idUser')
      localStorage.removeItem('orden')
    }
  
  //Signup, Login y Logout
    handleLogout = async () => {
      await SERVICE.logOut()
      localStorage.removeItem('idUser')
      localStorage.removeItem('orden')
      this.props.history.push('/')
      this.setState({
        loggedUser: null,
        isLogged: false,
        isAdmin:false
      })
      this.resetArchivos()
      this.resetArchivosCorte()
      this.resetOrden()
    }
    handleSignupSubmit = async e => {
      e.preventDefault()
      const form = this.state.formSignup
      if (form.email.localeCompare(form.emailConfirm)===0){
        if(form.password.localeCompare(form.passwordConfirm)===0) {
          form.phone=form.phone.split(' ').join('').split('-').join('')
          return await SERVICE.signup(form)
          .then(({data}) => {
              this.setState({
                formSignup: {
                  name:'',
                  phone:'',
                  email:'',
                  emailConfirm: '',
                  password: '',
                  passwordConfirm:''  
                }
              })
                return {
                user: data.user,
                msg: data.msg
              }
          })
          .catch((err) => {
            return {
              user: null,
              msg: err.response.data.msg
            }
          })
        }
        else
        {
          return {
            user: null,
            msg: 'Las contraseñas no coinciden.'
          }
        }
      }
      else
      {
        return {
          user: null,
          msg: 'Los emails no coinciden.'
        }
      }
      
    }
    handleLoginSubmit = e => {
      e.preventDefault()
      const form = this.state.formLogin
      return SERVICE.login(form)
        .then(async (
          {data}
        ) => {
          //console.log(data)
          if (data.user) {
            if (data.user.rol==='Admin') {this.setState({isAdmin:true,orders:data.orders})}
            this.setState({loggedUser:data.user,
                feed:true,
                isLogged: true,
                formDireccion:{...this.state.formDireccion,...data.user.direccion},
              })
            return {
              user: data.user,
              msg: 'Login realizado.'
            }
          }
          else {
          return {
            user: null,
            msg: 'Usuario inactivo.'
          }}
        })
        .catch(err => {
            this.setState({
            loggedUser: null,
            isLogged: false,
            formLogin: {
              email: '',
              password: ''
            }
          })
          return {
            user: null,
            msg: 'Email/contraseña inválidos.'
          }
        })
        .finally(() => this.setState({
          formLogin: {
            email: '',
            password: ''
          }
        }))
    }
  //recuperacion de la información al recargar
    restoreData=async()=>{
      const id=localStorage.getItem('idUser')
      const order=localStorage.getItem('orden')
      let msg='sin carga'
      if (id) {
      await SERVICE.profile(id)
        .then(async ({data})=>{
            if (data.user.rol==='Admin') {this.setState({isAdmin:true,orders:data.orders})}
            this.setState({loggedUser:data.user,
              feed:true,
              isLogged: true,
            })
            localStorage.setItem('idUser',data.user._id)
            msg='si hay usuario'
            if (order) {  
                await SERVICE.getOrder(order)
                .then(({data})=>{
                  localStorage.setItem('orden',data.order._id)
                  this.setOrden(data.order) 
                })
                .catch(err=>{
                  //error al obtener la orden
                  localStorage.removeItem('orden')
                  msg=err
                })
              
              }
            else{
              //si hay usuario pero no orden
            } 
          })
          .catch(err => {
            msg=null
            localStorage.removeItem('idUser')
            localStorage.removeItem('orden')
            this.setState({feed:true})
          }) 
     }  
      else {
        //no hay idUsuario registrado
        msg='null'
        localStorage.removeItem('idUser')
        localStorage.removeItem('orden')
        this.setState({
          loggedUser: null,
          isLogged: false,
          feed:true,
          isAdmin:false
        })
      }
      return msg
    }
  //reiniciar orden,archivos y archivos corte
    resetOrden=()=>{
      const newOrden={
        _id:'',
        archivos:[],
        fechaEnvio:'',
        subTotal:0,
        cargosAdicionales:0,
        envio:0,
        total:0,
        totalPagado:0,
        cliente:'',
        descripcion:'',
        factura:false,
        tipoEnvio:'',
        tiposEnvio:[],
        direccion:{},
        infoPago:null,
        pagoPendiente:true,
      }
      this.setState({formOrden:newOrden})  
    }
    resetArchivos=()=>{
      const indices=this.state.materials[0].indicesDefault;
      const newFile={
        escala:100,
        nuevo:true,
        soporte:false,
        fileURL:'',
        file:null,
        dimX:1,
        dimY:1,
        dimZ:1,
        dimX0:1,
        dimY0:1,
        dimZ0:1,
        name:'',
        units:'mm',
        fill:this.state.materials[0].fill[indices.fill].name,
        color:this.state.materials[0].color[indices.color].name,
        colorHEX:this.state.materials[0].color[indices.color].value,
        fileType:'',
        amount:1,
        quality:this.state.materials[0].quality[indices.quality].name.substring(0,6),
        comments:'',
        material:this.state.materials[0].name,
        cost:0,
        costs:{
          material:this.state.materials[0].value,
          quality:this.state.materials[0].quality[indices.quality].value,
          fill:this.state.materials[0].fill[indices.fill].value,
          color:this.state.materials[0].color[indices.color].cost
        },
        index:{
          material:0,
          quality:indices.quality,
          fill:indices.fill,
          color:indices.color
        },
        flag:false
      }
        this.setState({
        formArchivos:[newFile]})
    }
    resetArchivosCorte=()=>{
      const indices=this.state.materialsCorte[0].indicesDefault;
      const newFile={
        escala:100,
        fileURL:'',
        file:null,
        perimetro:0,
        dimX:1,
        dimY:1,
        dimX0:1,
        dimY0:1,
        name:'',
        units:'cm',
        unitsPrecio:'cm',
        grosor:this.state.materialsCorte[0].color[indices.color].grosor[indices.grosor[indices.color]].name,
        color:this.state.materialsCorte[0].color[indices.color].name,
        amount:1,
        comments:'',
        material:this.state.materialsCorte[0].name,
        cost:0,
        costs:{
          material:this.state.materialsCorte[0].color[indices.color].grosor[indices.grosor[indices.color]].costo,
          corte:this.state.materialsCorte[0].color[indices.color].grosor[indices.grosor[indices.color]].corte
        },
        index:{
          material:0,
          color:indices.color,
          grosor:indices.grosor
        },
        flag:false
      }
        this.setState({
        formArchivosCorte:[newFile]})
    }
  //edicion de dirección en perfil
    changeAddress=async (e)=>{
      e.preventDefault();
      let msg='';
      let code='';
      await SERVICE.changeAddress(this.state.formDireccion)
      .then(({data})=>{
        msg=data.msg;
        code='success';
        this.setState({loggedUser:{...this.state.loggedUser,direccion:{...data.user.direccion}}});
      })
      .catch(err=>{msg=err;code='error'})
      return {msg,code}
    }
  //Cambio y reinicio de password  
    resetPassword=async(e)=>{
      e.preventDefault()
      //console.log('entra a context')
      let msg='no hay mensaje'
      await SERVICE.resetPassword({email:this.state.formLogin.email})
      .then(resp=>{
        //console.log(resp);
        msg=resp.data.msg
      })
      .catch(err=>{
        msg='Error al reiniciar contraseña.'
      })
      //console.log(msg)
      return {msg};
    }
    submitChangePass=async(e)=>{
      e.preventDefault()
      let msg='no hay mensaje'
      await SERVICE.restorePassword({...this.state.formChangePass})
        .then(async(resp)=>{
          this.setState({
            formChangePass: {
              password: '',
              passwordConfirm: ''
            }
          })
          await SERVICE.profile(this.state.loggedUser._id)
          .then(({data})=>{
            this.setState({loggedUser:data.user})
            msg='Cambio exitoso.'
          }
          )
          .catch(err => {
            msg=err
          })
        })
        .catch((err)=>{
            msg= err
        })
        return msg
    }
  //transferencia de saldo
    transferCredit=async(e)=>{
      e.preventDefault()
      let msg='no hay mensaje'
      let estatus='success'
      const IdUser=localStorage.getItem('idUser')
      await SERVICE.transferCredit({...this.state.formTransferCredit,IdUser})
        .then(async({data})=>{
          if (data.user){
          this.setState({
            formTransferCredit: {
              email: '',
              credits: ''
            },
            loggedUser:{...this.state.loggedUser,credits:data.user.credits}
          })}
          else{
            this.setState({
              formTransferCredit: {
                email: '',
                credits: ''
              }
            })
          }
          msg=data.msg
          estatus=data.estatus
        })
        .catch((err)=>{
          console.log(err)
            msg= 'No se pudo hacer la transferencia.'
            estatus='error'
        })
        return {msg,estatus}
    }
  //Pagos de ordenes
    applyCredits=async()=>{
      const credito=this.state.loggedUser.credits
      const monto=Math.min(credito,this.state.formOrden.total)
      await SERVICE.applyCredits({IdUser:this.state.formOrden.cliente,monto,idOrden:this.state.formOrden._id,descripcion:this.state.formOrden.descripcion,fechaEnvio:this.state.formOrden.fechaEnvio,envio:this.state.formOrden.envio,total:this.state.formOrden.total,direccion:{...this.state.formOrden.direccion}})
      .then(({data})=>{
        this.setState({loggedUser:data.user,formOrden:{...this.state.formOrden,...data.order}})
        return {
          msg:null
        }
      })
      .catch(err => {
        
        return {
          msg: err
        }
      })
    }
    applyPayment=async(pago)=>{
      const orden={...this.state.formOrden}
      orden.infoPago={...pago}
      await SERVICE.applyPayment(orden)
      .then(({data})=>{
        this.setState({loggedUser:data.user,formOrden:{...this.state.formOrden,...data.order}})
        return {
          msg:null
        }
      })
      .catch(err => {
        
        return {
          msg: err
        }
      })
    }
  //Impresión 3D
    getDates=(totalCost)=>{
      const fecha=new Date()
      const options= { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      let extra=Math.ceil(totalCost/600)+1
      const fechaInicial=new Date(fecha.getFullYear(),fecha.getMonth(),fecha.getDate()+extra)
      if (fechaInicial.getDay()===0){
        extra=1;
      }else if(fechaInicial.getDay()===6){
        extra=2;
      }else{
        extra=0;
      }  
      const fechaFinal=new Date(fechaInicial.getFullYear(),fechaInicial.getMonth(),fechaInicial.getDate()+extra)
      const fechas=[fechaFinal.toLocaleDateString('es-MX', options)]
     return fechas
    }
    setDimensions=(x,y,z,index)=>{
      const obj1=this.state.formArchivos
      const escala=obj1[index].escala
      obj1[index].dimX=x*escala/100;
      obj1[index].dimY=y*escala/100;
      obj1[index].dimZ=z*escala/100;   
      obj1[index].dimX0=x;
      obj1[index].dimY0=y;
      obj1[index].dimZ0=z;   
      obj1[index].nuevo=false;      
      this.setState({formArchivos:[...obj1]})
    }
    handleEscala=(escala,index)=>{
      const obj1=this.state.formArchivos
      obj1[index].dimX=Math.round(obj1[index].dimX0*escala)/100;
      obj1[index].dimY=Math.round(obj1[index].dimY0*escala)/100;
      obj1[index].dimZ=Math.round(obj1[index].dimZ0*escala)/100;   
      obj1[index].escala=escala
      if (obj1[index].file)
        obj1[index].nuevo=false;      
      this.setState({formArchivos:[...obj1]})
    }
    handleFile=async (e,obj,flag,index=-1)=>{
      let Obj2 = this.state[obj]
      const file= e.target.files[0]
      Obj2[index].name =file.name
      Obj2[index].fileType=file.name.toLowerCase().endsWith('.stl')?'stl':'obj';
      Obj2[index].file=file
      Obj2[index].flag=flag
      /*const reader = new FileReader()
            reader.readAsDataURL( file )
            reader.onloadend = async() => Obj[index].FileUrl=reader.result
            */
      const formData = new FormData()
      formData.append('file', file)
      const IdUser=localStorage.getItem('idUser')
      formData.append('IdUser', IdUser)
      if (!flag) {
      await SERVICE.handleFile(formData).then(({data})=>{
        Obj2[index].fileURL=data.fileURL
      }).catch(err => console.log('error al cargar:'+err))
      }
      this.setState({
        formArchivos:Obj2
      })
      
    }
    addFile=()=>{
      const index=this.state.formArchivos.findIndex(archivo=>archivo.name==='')
      const indices=this.state.materials[0].indicesDefault;
      if (index===-1)
      {const newFile={
        escala:100,
        nuevo:true,
        soporte:false,
        fileURL:'',
        file:null,
        dimX:1,
        dimY:1,
        dimZ:1,
        dimX0:1,
        dimY0:1,
        dimZ0:1,
        name:'',
        units:'mm',
        fill:this.state.materials[0].fill[indices.fill].name,
        color:this.state.materials[0].color[indices.color].name,
        colorHEX:this.state.materials[0].color[indices.color].value,
        fileType:'',
        amount:1,
        quality:this.state.materials[0].quality[indices.quality].name.substring(0,6),
        comments:'',
        material:this.state.materials[0].name,
        cost:0,
        costs:{
          material:this.state.materials[0].value,
          quality:this.state.materials[0].quality[indices.quality].value,
          fill:this.state.materials[0].fill[indices.fill].value,
          color:this.state.materials[0].color[indices.color].cost
        },
        index:{
          material:0,
          quality:indices.quality,
          fill:indices.fill,
          color:indices.color
        },
        flag:false
      }
      const Obj3 = this.state.formArchivos
      Obj3.push(newFile)
      this.setState({
        formArchivos:Obj3
      })
      return ({msg:null})
    }
    else{
      return ({msg:`Falta ingresar el archivo ${index+1}.`})
    }
    }
    removeFile=(index)=>{
      const Obj4 = this.state.formArchivos
      Obj4.splice(index,1)
      if (Obj4.length===0){
        this.addFile()
      }
      this.setState({
        formArchivos:Obj4
      })
    } 
    handleInput = (e, obj,index=-1) => {
      const {
        name,
        value
      } = e.target
      if (index>=0) {
        let Obj8 = this.state[obj]
        Obj8[index][name] = value
        this.setState({
          formArchivos:Obj8
        })
      }
      else {
        let Obj1 = this.state[obj]
        Obj1[name] = value
        this.setState({
          Obj1
        })
      }
      
    }
    handleSelect = (e, index) => {
      const {
        name,
        value
      } = e.target
        let Obj9 = this.state.formArchivos
        Obj9[index].index[name] = value
        if (name.localeCompare('material')===0) {
          const indices=this.state.materials[value].indicesDefault;
          Obj9[index].material=this.state.materials[value].name
          Obj9[index].costs.material=this.state.materials[value].value
          Obj9[index].index.color=indices.color
          Obj9[index].color=this.state.materials[value].color[indices.color].name
          Obj9[index].colorHEX=this.state.materials[value].color[indices.color].value
          Obj9[index].costs.color=this.state.materials[value].color[indices.color].cost
          Obj9[index].index.fill=indices.fill
          Obj9[index].fill=this.state.materials[value].fill[indices.fill].name
          Obj9[index].costs.fill=this.state.materials[value].fill[indices.fill].value
          Obj9[index].index.quality=indices.quality
          Obj9[index].quality=this.state.materials[value].quality[indices.quality].name.substring(0,6)
          Obj9[index].costs.quality=this.state.materials[value].quality[indices.quality].value
          Obj9[index].soporte=false
        }
        else if (name.localeCompare('color')===0) {
          Obj9[index].color=this.state.materials[Obj9[index].index.material][name][value].name
          Obj9[index].colorHEX=this.state.materials[Obj9[index].index.material][name][value].value
          Obj9[index].costs.color=this.state.materials[Obj9[index].index.material][name][value].cost
        }
        else if (name.localeCompare('quality')===0) {
          Obj9[index][name]=this.state.materials[Obj9[index].index.material][name][value].name.substring(0,6)
          Obj9[index].costs[name]=this.state.materials[Obj9[index].index.material][name][value].value
        }
        else{
          Obj9[index][name]=this.state.materials[Obj9[index].index.material][name][value].name
          Obj9[index].costs[name]=this.state.materials[Obj9[index].index.material][name][value].value
        }
        
        this.setState({
          formArchivos:Obj9
        })
      
    }
    order=async (e)=>{
      e.preventDefault()
      let sum=0
      const {formArchivos}=this.state
      const index=formArchivos.findIndex(archivo=>archivo.name==='')
      if (index===-1){
        //Todos los objetos de formArchivos tienen archivo
        //Se guarda la información de costos y dimensiones que se tienen en pantalla
        for (let i=0;i<formArchivos.length;i++){
          formArchivos[i].cost=document.getElementById(`cost_${i}`).innerHTML.split(',').join('')*1
          const dims=document.getElementById(`Dims_${i}`).innerHTML.split(' x ')
          formArchivos[i].dimX=dims[0]
          formArchivos[i].dimY=dims[1]
          formArchivos[i].dimZ=dims[2]
          //se calcula el total
          sum+=Math.round((formArchivos[i].cost*1*formArchivos[i].amount*1)*100)/100
        }
        const fechas=this.getDates(sum)
        const archivos=this.state.formArchivos
        const orden={archivos:[...archivos],
        fechaEnvio:fechas[0],
        subTotal:sum,
        cargosAdicionales:0,
        envio:199,
        total:sum+199,
        totalPagado:0,
        cliente:this.state.loggedUser._id,
        descripcion:'',
        direccion:{...this.state.loggedUser.direccion},
        pagoPendiente:true,
        }     
        await SERVICE.order(orden).then(({data})=>{
          this.setState({loggedUser:data.user,formOrden:{...this.state.formOrden,...data.order},formDireccion:{...data.order.direccion}})
          localStorage.setItem('orden',data.order._id)
          this.resetArchivos()  
          return {data}
        }).catch(err => {console.log('error al ordenar:'+err)
        return {err}})
        return {msg:null}
      }
      else{
        return ({msg:`Antes de ordenar borra o ingresa el archivo ${index+1}.`})
      }
    }
  //Cargado y Actualización de ordenes
    cancelOrder=async(order)=>{
      let msg=null
      await SERVICE.cancelOrder(order)
      .then(({data})=>{
        this.setState({loggedUser:data.user})
      })
      .catch(err =>msg=err)
      return {msg}
    }
    updateOrder=async(order)=>{
      let msg=null
      await SERVICE.updateOrder(order)
      .then(({data})=>{
        this.setState({loggedUser:data.user,formOrden:{...this.state.formOrden,...data.order}})
      })
      .catch(err =>msg=err)
      return {msg}
    }
    setOrden=(orden,flag=true)=>{
      if (flag) {
        const fechas=orden.corte?this.getDatesCorte(orden.total):this.getDates(orden.total);
        orden.fechaEnvio=fechas[0];
      }
      this.setState({formOrden:{...this.state.formOrden,...orden},formDireccion:{...orden.direccion}})
      localStorage.setItem('orden',orden._id)
    }
   
  //Corte Laser
    removeFileCorte=(index)=>{
      const Obj4 = this.state.formArchivosCorte
      if (Obj4.length===1){
        this.addFileCorte()
      }
      Obj4.splice(index,1)
      const total=Obj4.reduce((total,archivo)=>total+(archivo.amount*archivo.cost),0)
      for (let i=0;i<Obj4.length;i++){
        let obj = document.createElement('object');
        obj.data = URL.createObjectURL(Obj4[index].file);
        obj.onload =async e =>{ 
            URL.revokeObjectURL(obj.data);
            const tags=obj.contentDocument.querySelectorAll('svg  path,rect,line,circle,ellipse,polyline,polygon')
                const nodeArray = Array.prototype.slice.call(tags);
                const bBox=await nodeArray.reduce(function(memo, node) {
                    if (node.getBBox) {
                    let bBox = node.getBBox();
                    memo.left = Math.min(memo.left, bBox.x);
                    memo.bottom = Math.min(memo.bottom, bBox.y);
                    memo.top = Math.max(memo.top, bBox.y+bBox.height);
                    memo.right = Math.max(memo.right, bBox.x+bBox.width);
                    } 
                    return memo;
                }, { left: Infinity, bottom: Infinity, top: 0, right: 0 });
                if (bBox.left<0 || bBox.bottom<0)
                    obj.contentDocument.getElementsByTagName('svg')[0].setAttribute("viewBox", `${bBox.left} ${bBox.bottom} ${bBox.right-bBox.left} ${bBox.top-bBox.bottom}`)
                
        }
        const div=document.getElementById(`svg_${index}`)
        div.innerHTML=''
        div.appendChild(obj);
      }
      this.setState({
        formArchivosCorte:Obj4,
        totalCorte:total
      })
    }
    addFileCorte=()=>{
      const index=this.state.formArchivosCorte.findIndex(archivo=>archivo.name==='')
      if (index===-1)
      {
        const ArchivosCorte = this.state.formArchivosCorte
        const newFile={
          escala:100,
          fileURL:'',
          file:null,
          perimetro:0,
          dimX:1,
          dimY:1,
          dimX0:1,
          dimY0:1,
          name:'',
          units:'cm',
          unitsPrecio:'cm',
          grosor:this.state.materialsCorte[0].color[0].grosor[0].name,
          color:this.state.materialsCorte[0].color[0].name,
          amount:1,
          comments:'',
          material:this.state.materialsCorte[0].name,
          cost:0,
          costs:{
            material:this.state.materialsCorte[0].color[0].grosor[0].costo,
            corte:this.state.materialsCorte[0].color[0].grosor[0].corte
          },
          index:{
            material:0,
            color:0,
            grosor:0
          },
          flag:false
        }
        ArchivosCorte.push(newFile)
        this.setState({
          formArchivosCorte:ArchivosCorte
        })
        return ({msg:null})
      }
      else{
        return ({msg:`Falta ingresar el archivo ${index+1}.`})
      }
    }
    handleFileCorte=async (file,flag,index=-1,bBox,lengths)=>{
      let Obj2 = this.state.formArchivosCorte
      Obj2[index].name =file.name
      Obj2[index].file=file
      Obj2[index].flag=flag
      Obj2[index].perimetro=await lengths.reduce((total, value)=>total+value )
      //console.log('perimetros',Obj2[index].perimetro,lengths)
      Obj2[index].dimX0=Math.abs(bBox.right-bBox.left)
      Obj2[index].dimY0=Math.abs(bBox.bottom-bBox.top)
      switch (Obj2[index].units){
        case 'mm':
          Obj2[index].dimX=Math.round(Obj2[index].dimX0/72*25.4*Obj2[index].escala)/100
          Obj2[index].dimY=Math.round(Obj2[index].dimY0/72*25.4*Obj2[index].escala)/100
          break;
        case 'cm':
          Obj2[index].dimX=Math.round(Obj2[index].dimX0/72*2.54*Obj2[index].escala)/100
          Obj2[index].dimY=Math.round(Obj2[index].dimY0/72*2.54*Obj2[index].escala)/100
          break;
        case 'in':
          Obj2[index].dimX=Math.round(Obj2[index].dimX0/72*Obj2[index].escala)/100
          Obj2[index].dimY=Math.round(Obj2[index].dimY0/72*Obj2[index].escala)/100
          break;
        default:
          break;
      }
      switch (this.state.materialsCorte[Obj2[index].index.material].unidadesPrecio) {
        case 'mm':
          Obj2[index].costs.material=Math.round(Obj2[index].dimX0*Obj2[index].dimY0/72*25.4/72*25.4*100)/100
          Obj2[index].costs.corte=Math.round(Obj2[index].perimetro/72*25.4*100)/100
          break;
        case 'cm':
          Obj2[index].costs.material=Math.round(Obj2[index].dimX0*Obj2[index].dimY0/72*2.54/72*2.54*100)/100
          Obj2[index].costs.corte=Math.round(Obj2[index].perimetro/72*2.54*100)/100
          break;
        case 'in':
          Obj2[index].costs.material=Math.round(Obj2[index].dimX0*Obj2[index].dimY0/72/72*100)/100
          Obj2[index].costs.corte=Math.round(Obj2[index].perimetro/72*100)/100
          break;
          default:
            break;
      }
      const costo=this.state.materialsCorte[Obj2[index].index.material].color[Obj2[index].index.color].grosor[Obj2[index].index.color]
      Obj2[index].cost=Math.round(((costo.corte* Obj2[index].costs.corte )+ (costo.costo* Obj2[index].costs.material))*Obj2[index].escala)/100
      const total=Obj2.reduce((total,archivo)=>total+(archivo.amount*archivo.cost),0)
      const formData = new FormData()
      formData.append('file', file)
      const IdUser=localStorage.getItem('idUser')
      formData.append('IdUser', IdUser)
      if (!flag) {
      await SERVICE.handleFileCorte(formData).then(({data})=>{
        Obj2[index].fileURL=data.fileURL
      }).catch(err => console.log('error al cargar:'+err))
      }
      this.setState({
        formArchivosCorte:Obj2,
        totalCorte:total
      })
    }
    handleInputCorte = (e,index=-1)=>{
      const {name, value} = e.target
      let Obj8 = this.state.formArchivosCorte
      Obj8[index][name] = value
      if (name.localeCompare('units')===0){
        switch (value){
          case 'mm':
            Obj8[index].dimX=Math.round(Obj8[index].dimX0/72*25.4*Obj8[index].escala)/100
            Obj8[index].dimY=Math.round(Obj8[index].dimY0/72*25.4*Obj8[index].escala)/100
            break;
          case 'cm':
            Obj8[index].dimX=Math.round(Obj8[index].dimX0/72*2.54*Obj8[index].escala)/100
            Obj8[index].dimY=Math.round(Obj8[index].dimY0/72*2.54*Obj8[index].escala)/100
            break;
          case 'in':
            Obj8[index].dimX=Math.round(Obj8[index].dimX0/72*Obj8[index].escala)/100
            Obj8[index].dimY=Math.round(Obj8[index].dimY0/72*Obj8[index].escala)/100
            break;
          default:
            break;
        }
        
      }
      const total=Obj8.reduce((total,archivo)=>total+(archivo.amount*archivo.cost),0)
      this.setState({
        formArchivosCorte:Obj8,
        totalCorte:total
      })
    }
    handleSelectCorte = (e, index) => {
      const { name,value} = e.target
      let Obj9 = this.state.formArchivosCorte
      Obj9[index].index[name] = value
      if (name.localeCompare('material')===0) {
        const indices=this.state.materialsCorte[value].indicesDefault;
        Obj9[index].material=this.state.materialsCorte[value].name
        Obj9[index].index.color=indices.color
        Obj9[index].color=this.state.materialsCorte[value].color[indices.color].name
        Obj9[index].index.grosor=indices.grosor[indices.color]
        Obj9[index].grosor=this.state.materialsCorte[value].color[indices.color].grosor[indices.grosor[indices.color]].name
      }
      else if (name.localeCompare('color')===0) {
        Obj9[index].color=this.state.materialsCorte[Obj9[index].index.material].color[value].name
        Obj9[index].index.grosor=this.state.materialsCorte[Obj9[index].index.material].indicesDefault.grosor[value]
        Obj9[index].grosor=this.state.materialsCorte[Obj9[index].index.material].color[value].grosor[Obj9[index].index.grosor].name
      }
      else{
        Obj9[index][name]=this.state.materialsCorte[Obj9[index].index.material].color[Obj9[index].index.color].grosor[value].name
      }

      const costo=this.state.materialsCorte[Obj9[index].index.material].color[Obj9[index].index.color].grosor[Obj9[index].index.grosor]
      Obj9[index].cost=Math.round(((costo.corte* Obj9[index].costs.corte )+ (costo.costo* Obj9[index].costs.material))*Obj9[index].escala)/100
      const total=Obj9.reduce((total,archivo)=>total+(archivo.amount*archivo.cost),0)
      this.setState({   
        formArchivosCorte:Obj9,
        totalCorte:total
      })
    
    }
    handleEscalaCorte=(escala,index)=>{
      const Obj1=this.state.formArchivosCorte
      Obj1[index].escala=escala  
      switch (Obj1[index].units){
        case 'mm':
          Obj1[index].dimX=Math.round(Obj1[index].dimX0/72*25.4*Obj1[index].escala)/100
          Obj1[index].dimY=Math.round(Obj1[index].dimY0/72*25.4*Obj1[index].escala)/100
          break;
        case 'cm':
          Obj1[index].dimX=Math.round(Obj1[index].dimX0/72*2.54*Obj1[index].escala)/100
          Obj1[index].dimY=Math.round(Obj1[index].dimY0/72*2.54*Obj1[index].escala)/100
          break;
        case 'in':
          Obj1[index].dimX=Math.round(Obj1[index].dimX0/72*Obj1[index].escala)/100
          Obj1[index].dimY=Math.round(Obj1[index].dimY0/72*Obj1[index].escala)/100
          break;
        default:
          break;
      }  
      const costo=this.state.materialsCorte[Obj1[index].index.material].color[Obj1[index].index.color].grosor[Obj1[index].index.grosor]
      //console.log(costo)
      Obj1[index].cost=Math.round(((costo.corte* Obj1[index].costs.corte )+ (costo.costo* Obj1[index].costs.material))*Obj1[index].escala)/100
      const total=Obj1.reduce((total,archivo)=>total+(archivo.amount*archivo.cost),0)
      this.setState({
        formArchivosCorte:Obj1,
        totalCorte:total
      })
    }
    getDatesCorte=(totalCost)=>{
      const fecha=new Date()
      const options= { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      let extra=fecha.getDay()<4?1+4-fecha.getDay():8+6-fecha.getDay();
      const fechaFinal=new Date(fecha.getFullYear(),fecha.getMonth(),fecha.getDate()+extra)
      const fechas=[fechaFinal.toLocaleDateString('es-MX', options)]
      fechas.push(new Date(fechaFinal.getFullYear(),fechaFinal.getMonth(),fechaFinal.getDate()+7).toLocaleDateString('es-MX', options))
      fechas.push(new Date(fechaFinal.getFullYear(),fechaFinal.getMonth(),fechaFinal.getDate()+14).toLocaleDateString('es-MX', options))
      return fechas
    }
    orderCorte=async (e)=>{
      e.preventDefault()
      const index=this.state.formArchivosCorte.findIndex(archivo=>archivo.name==='')
      if (index===-1){
        //Todos los objetos de formArchivos tienen archivo
        const sum=this.state.totalCorte
        const fechas=this.getDatesCorte(sum)
        const archivos=this.state.formArchivosCorte
        const orden={archivos:[...archivos],
        fechasEnvio:[...fechas],
        fechaEnvio:fechas[0],
        subTotal:sum,
        cargosAdicionales:0,
        envio:0,
        total:sum,
        totalPagado:0,
        cliente:this.state.loggedUser._id,
        descripcion:'',
        corte:true}     
        await SERVICE.orderCorte(orden).then(({data})=>{
          this.setState({loggedUser:data.user,formOrden:{...this.state.formOrden,...data.order}})
          localStorage.setItem('orden',data.order._id)
          this.resetArchivosCorte()  
          return {data}
        }).catch(err => {console.log('error al ordenar:'+err)
        return {err}})
        return {msg:null}
      }
      else{
        return ({msg:`Antes de ordenar borra o ingresa el archivo ${index+1}.`})
      }
    }

    //modulo admin
    printOrder=async (idorder)=>{
      let msg=null
      await SERVICE.printOrder(idorder)
      .then(({data})=>{
        this.setState({orders:data.orders})
        msg=data.msg
      })
      .catch(err =>msg=err)
      return {msg}
    }
    cancelarOrder=async (idorder,porcentaje)=>{
      let msg=null
      await SERVICE.cancelarOrder(idorder,porcentaje)
      .then(({data})=>{
        this.setState({orders:data.orders})
        msg=data.msg
      })
      .catch(err =>msg=err)
      return {msg}
    }
    fileStatus=async(file,estatus)=>{
      let msg=null
      await SERVICE.fileStatus(file,estatus)
      .then(({data})=>{
        this.setState({orders:data.orders})
        msg=data.msg
      })
      .catch(err =>{msg=err
      })
      return {msg}
    }
    handleCPInfo=(direccion)=>{
      this.setState({formDireccion:{...this.state.formDireccion,delegacion:direccion.municipio,colonia:'',
      estado:direccion.estado}})
    }

    handleDistancia=(info,orden)=>{
      this.setState({formOrden:{...this.state.formOrden,...orden},formDireccion:{info:{...info}}}) 
    }
    handleTipoEnvio=async(e)=>{
      let index=this.state.formOrden.tiposEnvio.map((e)=>e.name).indexOf(e.target.value)
      await SERVICE.updateTipoEnvio(this.state.formOrden.tiposEnvio[index],this.state.formOrden._id,this.state.formOrden.subTotal)
      .then(({data})=>{
        this.setState({formOrden:{...data.order}});
      })
      .catch(err=>{
        console.log(err)
        return err
      })
    }
    handleEnvioUP=async(e)=>{
      let nuevaDireccion
      let tipo
      let envio
      if (e.target.checked.toString().toLowerCase()==='true'){
        nuevaDireccion={
          calle: 'Campus Malaga, Augusto Rodin',
          ext:'498',
          int:'',
          cp:'03920',
          delegacion:'Benito Juárez',
          estado:'Ciudad de México',
          colonia:'Insurgentes Mixcoac',
          info:{
            duration: {
              "value": 1200,
              "text": "20 minutos"
            },
            distance: {
            "value": 7000,
            "text": "7 km"
            }
          }
        }
        envio=0
        tipo='Universidad Panamericana'
      }
      else{
       nuevaDireccion={...this.state.loggedUser.direccion}
       tipo=''
       envio=199
      }
      await SERVICE.updateDireccionEnvio(nuevaDireccion,tipo,this.state.formOrden._id,this.state.formOrden.subTotal,envio)
      .then(({data}) => {
        this.setState({formDireccion:{...data.order.direccion},formOrden:{...this.state.formOrden,...data.order}})
      })
      .catch((err)=>{
        console.log(err)
        return err
      })
    }
    render() {
      const {
        state,
        handleEscala,
        handleInput,
        handleSelect,
        removeFile,
        addFile,
        handleFile,
        handleSignupSubmit,
        handleLoginSubmit,
        handleLogout,
        setDimensions,
        order,
        resetArchivos,
        changeAddress,
        transferCredit,
        submitChangePass,
        applyCredits,
        setOrden,
        restoreData,
        cancelOrder,
        updateOrder,
        applyPayment,
        resetPassword,
        handleEscalaCorte,
        handleInputCorte,
        handleSelectCorte,
        removeFileCorte,
        addFileCorte,
        handleFileCorte,
        getDatesCorte,
        orderCorte,
        printOrder,
        cancelarOrder,
        fileStatus,
        handleCPInfo,
        handleDistancia,
        handleTipoEnvio,
        handleEnvioUP,
      } = this
      return ( <MyContext.Provider value = {
        {
          state,
          handleInput,
          handleSignupSubmit,
          handleLoginSubmit,
          handleLogout,
          setDimensions,
          handleFile,
          addFile,
          removeFile,
          order,
          resetArchivos,
          changeAddress,
          transferCredit,
          submitChangePass,
          applyCredits,
          handleSelect,
          setOrden,
          restoreData,
          cancelOrder,
          updateOrder,
          applyPayment,
          resetPassword,
          handleEscala,
          handleEscalaCorte,
          handleInputCorte,
          handleSelectCorte,
          removeFileCorte,
          addFileCorte,
          handleFileCorte,
          getDatesCorte,
          orderCorte,
          printOrder,
          cancelarOrder,
          fileStatus,
          handleCPInfo,
          handleDistancia,
          handleTipoEnvio,
          handleEnvioUP,
        }
      } > {
        this.props.children
      } </MyContext.Provider>)
    }
  }
  
  export default withRouter(Context)