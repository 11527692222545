import React,{useContext,useState} from 'react'
import {Box,Select, Input,Text, Flex,IconButton,Textarea,Button,Icon,useToast,
    NumberInput,NumberInputField,InputRightElement,Slider,SliderFilledTrack,SliderTrack,SliderThumb} from '@chakra-ui/core'
import {MyContext} from '../Context'
import { FaPlus,FaMinus,FaPercent} from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import { svgPathProperties } from "svg-path-properties";

function FileViewer({formArchivo,index}) {
    const toast=useToast();
    const context=useContext(MyContext)
    const materials=context.state.materialsCorte
    const removeFile=()=>{
        if (context.state.formArchivosCorte.length===1){
            setUploadFile(false)
            document.getElementById(`svg_0`).innerHTML=''
        }
        context.removeFileCorte(index)
    }
    const handleInput=async (e)=>{
      await context.handleInputCorte(e,index)
    }
    const handleSelect=async (e)=>{
        await context.handleSelectCorte(e,index)
      }
    
    const handleFile=async (e)=>{
        let flag=false
        let lengths=[]
        const file = e.target.files[0];
        if(file.type === 'image/svg+xml'){
            if (e.target.files[0].size> 10000000){
                flag=true
            }
            let obj = document.createElement('object');
            obj.data = URL.createObjectURL(file);
            obj.onload =async e =>{ 
                URL.revokeObjectURL(obj.data);
                //console.log(obj)
                const tags=obj.contentDocument.querySelectorAll('svg  path,rect,line,circle,ellipse,polyline,polygon')
                const nodeArray = Array.prototype.slice.call(tags);
                const bBox=await nodeArray.reduce(function(memo, node) {
                    if (node.getBBox) {
                    let bBox = node.getBBox();
                    memo.left = Math.min(memo.left, bBox.x);
                    memo.bottom = Math.min(memo.bottom, bBox.y);
                    memo.top = Math.max(memo.top, bBox.y+bBox.height);
                    memo.right = Math.max(memo.right, bBox.x+bBox.width);
                    } 
                    return memo;
                }, { left: Infinity, bottom: Infinity, top: 0, right: 0 });
                if (bBox.left<0 || bBox.bottom<0)
                    obj.contentDocument.getElementsByTagName('svg')[0].setAttribute("viewBox", `${bBox.left} ${bBox.bottom} ${bBox.right-bBox.left} ${bBox.top-bBox.bottom}`)
                
                for (let i=0;i<tags.length;i++){
                    let tag=tags[i].outerHTML
                    let aux=tag.split('"')
                    switch (aux[0].split(' ')[0]) {
                        case '<path':
                            const path=aux[aux.indexOf(' d=')+1]
                            const largo=new svgPathProperties(path);
                            lengths.push(largo.getTotalLength())
                            break;
                        case '<rect':
                            const height=Number(aux[aux.indexOf(' height=')+1])
                            const width=Number(aux[aux.indexOf(' width=')+1])
                            lengths.push(2*(height+width));
                            break;
                        case '<line':
                            const x1=Number(aux[aux.indexOf(' x1=')+1])
                            const y1=Number(aux[aux.indexOf(' y1=')+1])
                            const x2=Number(aux[aux.indexOf(' x2=')+1])
                            const y2=Number(aux[aux.indexOf(' y2=')+1])
                            lengths.push(Math.sqrt(Math.pow(x1-x2,2)+Math.pow(y1-y2,2)));
                            break;
                        case '<circle':
                            const radius=Number(aux[aux.indexOf(' r=')+1])
                            lengths.push(2*Math.PI*radius);
                            break;
                        case '<ellipse':
                            const radiusX=Number(aux[aux.indexOf(' rx=')+1])
                            const radiusY=Number(aux[aux.indexOf(' rx=')+1])
                            lengths.push(2*Math.PI*Math.sqrt((Math.pow(radiusX,2)+Math.pow(radiusY,2))/2));
                            break;
                        case '<polyline':
                            const points=aux[aux.indexOf(' points=')+1].replace(/\s+/g, ' ').trimEnd()
                            lengths.push(getPolyLength(points,false));
                            break;
                        case '<polygon':
                            //console.log(tag,aux)
                            const points2=aux[aux.indexOf(' points=')+1].replace(/\s+/g, ' ').trimEnd()
                            lengths.push(getPolyLength(points2,true));
                            break;
                        default:
                            break;
                    }
                }
                context.handleFileCorte(file,flag,index,bBox,lengths)
            };
            const div=document.getElementById(`svg_${index}`)
            div.appendChild(obj);

            setUploadFile(true)
            if (flag){
                toast({
                title: 'Archivo pesado',
                description: 'Si vas a ordenar este archivo necesitarás enviarlo por correo.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position:'top-left'
                })
            }
        }
        else {
            toast({
                title: 'Formato incorrecto',
                description: 'El archivo que cargaste no es .SVG.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position:'top-left'
              })
        }

    }
    const getPolyLength=(points,closed)=>{
        const puntos=points.split(' ')
        //console.log(puntos)
        let p1
        let p2
        let perimetro=0
        for (let i=0;i<puntos.length-1;i++){
            p1=puntos[i].split(',')
            p2=puntos[i+1].split(',')
            //console.log(p1,p2)
            perimetro+=Math.sqrt(Math.pow(Number(p1[0])-Number(p2[0]),2)+Math.pow(Number(p1[1])-Number(p2[1]),2))
        }
        if (closed){
            //se calcula la distancia entre el primer y el último punto
            //en p2 ya se trae la info del último punto
            p1=puntos[0].split(',')
            //console.log(p1,p2)
            perimetro+=Math.sqrt(Math.pow(p1[0]-p2[0],2)+Math.pow(p1[1]-p2[1],2))
        }
        //console.log('perimetro',perimetro)
        return perimetro
    }
    const handleCount=(sign,e)=>{
        if(sign===1){
            const d={
                target:{
                    name:'amount',
                    value:formArchivo.amount+sign
                }
            }
            handleInput(d)
        }
        else if (sign===0){
            const d={
                target:{
                    name:'amount',
                    value:e.target.value===0?1:Math.abs(e.target.value)
                }
            }
            handleInput(d)
        }
        else if(sign===-1){
                const d={
                    target:{
                        name:'amount',
                        value:Math.max(formArchivo.amount+sign,1)
                    }
                }
                handleInput(d)

        }
        
    }
    const handleEscala=value=>{
        context.handleEscalaCorte(Math.round(value*100)/100,index)
    }
    const handleDim=(value,dim)=>{
        let value0;
        switch (dim){
            case 1:
                value0=formArchivo.dimX0;
                break;
            case 2:
                value0=formArchivo.dimY0;
                break;
            default:
                break;
        }
        switch (formArchivo.units){
            case 'mm':
              value*=(72/25.4)
              break;
            case 'cm':
              value*=(72/2.54)
              break;
            case 'in':
              value*=72
              break;
            default:
              break;
          } 
          context.handleEscalaCorte(Math.round((value/value0)*10000)/100,index)
    }
    const [uploadFile,setUploadFile]=useState(false)
    
    return (
        <Box display="flex" flexDirection={{sm:'column',md:'row'}} justifyContent={{sm:'center',md:'space-around'}} alignItems='center' color='color5' margin='15px 15px 30px'>
            <Box display="flex" flexDirection='column' justifyContent='space-between'>
                <input type="file" id={`archivo_${index}`} name='archivo' 
                            accept={'.Svg'}
                            onChange={(e) => {handleFile(e)}}  />
                {!uploadFile ?
                    <Button alignSelf='center' as='label' width='200px' size="md" borderRadius='100px' backgroundColor='color3' color='color6' leftIcon={MdFileUpload} htmlFor={`archivo_${index}`}>Carga tu .SVG</Button>:
                    <Button alignSelf='center' as='label' width='200px' size="md" borderRadius='100px' backgroundColor='color3' color='color6' isDisabled={true}>Archivo Seleccionado</Button>
                }
                <Button alignSelf='center' size='sm' mt={2} width='200px' borderRadius='100px' backgroundColor='delete' color='color4' onClick={()=>removeFile()}><Icon name='delete' mr={2}></Icon>Borrar archivo</Button>
                
                <Box w={{sm:'80vw',md:'20vw'}}>
                    <label mt={1} mb={1} htmlFor="material">Material</label>
                    <Select size='sm' value={formArchivo.index.material} name="material" id='material' className="select"  onChange={(e)=>handleSelect(e)}>
                        <option disabled>Selecciona un material</option>
                        {materials && materials.map((material,index)=>
                            <option key={index} value={index}>{material.name}</option>
                        )}              
                    </Select>
                </Box>
                <Box w={{sm:'80vw',md:'20vw'}}>
                    <label mt={1} mb={1} htmlFor="color">Color</label>
                    <Select size='sm' value={formArchivo.index.color} name="color" id='color' className="select"  onChange={(e)=>handleSelect(e)}>
                        <option disabled>Selecciona un color</option>
                        {materials && materials[formArchivo.index.material].color.map((color,index)=>
                            <option key={index} value={index}>{color.name}</option>
                        )} 
                    </Select>
                </Box>
                <Box w={{sm:'80vw',md:'20vw'}}>
                    <label mt={1} mb={1} htmlFor="grosor">Grosor</label>
                    <Select size='sm' value={formArchivo.index.grosor} name="grosor" id='grosor'className="select"  onChange={(e)=>handleSelect(e)}>
                        <option disabled>Selecciona un color</option>
                        {materials && materials[formArchivo.index.material] && materials[formArchivo.index.material].color[formArchivo.index.color].grosor.map((grosor,index)=>
                            <option key={index} value={index}>{grosor.name}</option>
                        )} 
                    </Select>
                </Box>
                <Box w={{sm:'80vw',md:'20vw'}} display='flex' flexDirection='column'>
                    <Text as='label'>Dimensiones</Text>
                    <Flex w='100%' mt={1} mb={1} justifyContent='space-between'>
                        <Flex justifyContent='flex-start' mr='5px'>
                            <NumberInput type='number' size='sm' min={0} step={1} precision={2}
                                maxW="80px" fontSize='sm'
                                value={formArchivo.escala}
                                onChange={handleEscala}
                                    name='escalaI'
                            >
                            <NumberInputField textAlign='left' />
                            <InputRightElement m='10px' children={<Box as={FaPercent} color="color3" />} />
                            </NumberInput>
                        </Flex>
                        <Slider color='teal' name='escala' size='sm'  w='60%' min={0} max={300}  step={1} flex="1" value={formArchivo.escala} onChange={handleEscala}>
                            <SliderTrack />
                            <SliderFilledTrack />
                            <SliderThumb
                            fontSize="xs"
                            fontWeight='bold'
                            width="32px"
                            height="20px"
                            children={`<>`}
                            />
                        </Slider>
                    </Flex>
                       
                    <Flex mt={1} mb={1}>
                        <Flex>
                            <NumberInput mr={1} name='dimX' value={formArchivo.dimX} fontSize='xs' w='40%' size='sm' min={0} id={`DimX2_${index}`} onChange={e=>handleDim(e,1)}> <NumberInputField textAlign='center'/> </NumberInput> x
                            <NumberInput ml={1} name='dimY' value={formArchivo.dimY} fontSize='xs' w='40%' size='sm' min={0} id={`DimY2_${index}`} onChange={e=>handleDim(e,2)}> <NumberInputField textAlign='center'/> </NumberInput> 
                        </Flex> 
                        <Select size='sm' w='50%' display="flex" justifyContent="space-around" name="units" onChange={e => handleInput(e)} value={formArchivo.units}>
                            <option value="cm">cm</option>
                            <option value="mm">mm</option>
                            <option value="in">in</option>
                        </Select>
                    </Flex>
                </Box>
                <label htmlFor="amount" mt={1} mb={1}>Cantidad</label>
                <Box display='flex' flexDirection='row' justifyContent='center'>
                    <IconButton color='color6' alignSelf='center' size='xs' marginRight='5px' isRound={true} variantColor="teal" fontSize="15px" icon={FaMinus} onClick={()=>{handleCount(-1)}}/>
                    <Input size='sm' width='70px' textAlign='center' type='number' name='amount' value={formArchivo.amount} onChange={(e)=>handleCount(0,e)}/> 
                    <IconButton color='color6' alignSelf='center' size='xs' marginLeft='5px' isRound={true} variantColor="teal" fontSize="15px" icon={FaPlus} onClick={()=>{handleCount(1)}}/>
                </Box>
            </Box>
            <Flex display='flex' flexDirection='column' justify='space-between'>
                <Text isTruncated={true}>Archivo: {formArchivo.name}</Text>
                
                <Box width='350px' backgroundColor='color2' height='350px' overflow='scroll'  id={`svg_${index}`}>

                </Box> 
        
                    <Text>Dimensiones: {formArchivo.dimX} x {formArchivo.dimY} {formArchivo.units}</Text>
                </Flex> 
                <Box display="flex" flexDirection='column' >
                    <Button marginBottom={{sm:'20px',md:'10vh'}} borderRadius='100px' leftIcon="small-close" variantColor="teal" variant="outline" onClick={()=>removeFile()}>
                        Eliminar archivo
                    </Button>
                    <Box display="flex" flexDirection='column' textAlign='left'>
                        <Text fontWeight='bold'>Comentarios:</Text>
                        <Textarea width='200px' height='75px' resize='none' name='comments'  value={formArchivo.comments}
                            onChange={e => handleInput(e)}
                            placeholder="Cuéntanos si tienes algún requerimiento especial :)"
                            size="sm"/>
                    </Box>
                    <br />
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='200px'><Text>Costo por pieza:</Text> <Text >${formArchivo.cost.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='200px'><Text>Total piezas:</Text> <Text>{formArchivo.amount}</Text></Box>
                    <Box mt={10} mb='10vh' display='flex' flexDirection='row' justifyContent='space-between' width='200px' fontWeight='bold'><Text>Subtotal:</Text> <Text fontWeight='bold' >${(formArchivo.cost*formArchivo.amount).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                    <Text maxW='200px' id={`warn_${index}`} color='tomato' fontWeight='bolder'> </Text>

                </Box> 
                
        </Box>   
    )
}

export default FileViewer





