import React,{useContext} from 'react'
import {MyContext} from '../Context'
import {Text, Box,Button,useToast,Select,FormControl,Stack,Input, FormLabel,Spinner} from "@chakra-ui/core";
import SERVICE from '../services/index'
import axios from 'axios';

const sepomex = axios.create({ withCredentials: false, baseURL:'https://api.copomex.com/query'});

function DireccionEnvio() {
    const context=useContext(MyContext)
    const toast = useToast()
    const [loading,setLoading]=React.useState(false)
    const [colonias,setColonias]=React.useState([context.state.formOrden.direccion.colonia]);
    const handleCP=(e)=>{
        context.handleInput(e, 'formDireccion')
        if(e.target.value.length===5){
            sepomex.get('/info_cp/'+e.target.value+'?type=simplified&token=e7f311a0-7cb8-4cc8-a26b-561c0258cb62')
            .then(resp=>{
                //console.log(resp)
                setColonias([...resp.data.response.asentamiento])    
                context.handleCPInfo(resp.data.response);
            })
            .catch(err=>{
                console.log(err)
                toast({
                    title: 'CP Inexistente', 
                    description: 'No se pudo encontrar el código postal que capturaste.', 
                    status: 'error',
                    duration: 3000, 
                    isClosable: true,
                    position:'bottom-left'
                })
            })
        }
    }

    const changeAddress=(e)=>{
        e.preventDefault();
        setLoading(true); 
        SERVICE.distancia(context.state.formDireccion,context.state.formOrden._id)
        .then(resp=>{
            toast({
                title: 'Actualización', 
                description: 'Ahora sólo elige un tipo de envío.', 
                status: 'success',
                duration: 5000, 
                isClosable: true,
                position:'bottom-left'
            })
            //console.log(resp);
            if (resp.data.info){
                context.handleDistancia(resp.data.info,resp.data.order);
            }
        })
        .catch(err=>{
            toast({
                title: 'Actualización', 
                description: 'No se pudo calcular. Intenta más tarde.', 
                status: 'error',
                duration: 3000, 
                isClosable: true,
                position:'bottom-left'
            })
            console.log(err);
        })

        setLoading(false); 
    }


   
    return (
        <>

            <Box display='flex' flexDir='column' justifyContent='center' w='100%' color='color5'> 
                <Text fontWeight='bold'>Dirección de envío</Text>
                <Text  fontSize="xs"> Nota: Calcula el envío y elige un tipo para saber el costo real de envío.</Text>
                <FormControl as="form" onSubmit={e=>changeAddress(e)} display="flex" h="60%" justifyContent="center" w="100%" p={4}>
                    <Stack color="color5" width="100%">
                        <Box w='90%'>
                            <FormLabel htmlFor="calle">Calle*</FormLabel>
                            <Input size='sm' id="calle" name="calle" value={context.state.formDireccion.calle} onChange={(e) => context.handleInput(e, 'formDireccion')} type="text" roundedLeft="0" isRequired />
                        </Box>
                        <Box w='90%' display='flex' flexDir={{sm:'column',md:'row'}} justifyContent='space-between'>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="ext">Ext.*</FormLabel>
                                <Input size='sm' id='ext' name="ext" value={context.state.formDireccion.ext} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" roundedLeft="0" isRequired/>
                            </Box>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="int">Int.</FormLabel>
                                <Input size='sm' id="int" name="int" value={context.state.formDireccion.int} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" roundedLeft="0" />
                            </Box>
                        </Box>
                        <Box w='90%' display='flex' flexDir={{sm:'column',md:'row'}} justifyContent='space-between'>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="cp">Código Postal*</FormLabel>
                                <Input size='sm' id="cp" maxLength="5" minLength="5" name="cp" value={context.state.formDireccion.cp} onChange={(e) => handleCP(e)} color="color5" type="text" rounded="0"  isRequired/>
                            </Box>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="colonia" justifySelf="left">Colonia*</FormLabel>
                                <Select size='sm' fontSize='sm' display="flex" justifyContent="space-between" variantColor='teal'id="colonia" name="colonia" onChange={e => context.handleInput(e, 'formDireccion')} value={context.state.formDireccion.colonia}>
                                    <option value='' disabled>Selecciona tu colonia</option>
                                    {colonias.map((colonia,index)=>
                                        <option key={index} value={colonia}>{colonia}</option>
                                    )}
                                </Select>
                            </Box>
                        </Box>
                        
                        
                        <Box w='90%' display='flex' flexDir={{sm:'column',md:'row'}} justifyContent='space-between'>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="estado" justifySelf="left">Estado*</FormLabel>
                                <Input size='sm' id="estado" name="estado" value={context.state.formDireccion.estado} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0" isRequired isDisabled={true}/>
                            </Box>
                            <Box w={{sm:'100%',md:'40%'}}>
                                <FormLabel htmlFor="delegacion" justifySelf="left">Delegacion/Municipio*</FormLabel>
                                <Input size='sm' id="delegacion" name="delegacion" value={context.state.formDireccion.delegacion} onChange={(e) => context.handleInput(e, 'formDireccion')} color="color5" type="text" rounded="0"  isRequired isDisabled={true}/>
                            </Box>
                        </Box>
                        <Button alignSelf='center' isDisabled={loading} type="submit" width='150px' size="sm" mt={5} justifySelf='center' borderRadius='100px' backgroundColor='color3' color='color6'>
                            Calcular Envío
                        </Button>
                    </Stack>
                </FormControl>  
            </Box>
       
            {loading && 
                <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} left={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
                    <Text color='color1' fontWeight='bold' fontSize='5xl'>Calculando</Text>
                    <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="teal.700"
                    size="xl"
                    />
                </Box>
            }
       </>
    )
}

export default DireccionEnvio
