import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home'
import FAQ from './pages/FAQ'
import Cotizador from './pages/Cotizador';
import Corte from './pages/Cotizador2';
import Profile from './pages/Profile'
import ProfileAdmin from './pages/ProfileAdmin'
import ResumenArchivos from './pages/ResumenArchivos'
import ResumenOrdenes from './pages/ResumenOrdenes'
import ResumenOrdenesCorte from './pages/ResumenOrdenesCorte'
import AvisoPrivacidad from './pages/AvisoPrivacidad'
import Pago from './pages/Pago'
import Detalle from './pages/Detalle'
import Navbar from './components/Navbar'
import NotFound from './components/404/NotFound.js';
import { ThemeProvider, CSSReset, Box } from '@chakra-ui/core'
import theme from './theme'
import Context from './Context'

const Router = () => (
  <BrowserRouter>
    <Context>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Navbar />
        <Box width='100vw' height='10vh' />
        <Switch>
          <Route exact path="/cotizador" component={Cotizador} />
          <Route exact path="/cotizadorCorte" component={Corte} />
          <Route exact path="/" component={Home} />
          <Route exact path="/preguntasFrecuentes" component={FAQ} />
          <Route exact path="/perfil" component={Profile} />
          <Route exact path="/perfilAdmin" component={ProfileAdmin} />
          <Route exact path="/resumenArchivos" component={ResumenArchivos} />
          <Route exact path="/resumenOrdenes" component={ResumenOrdenes} />
          <Route exact path="/resumenOrdenesCorte" component={ResumenOrdenesCorte} />
          <Route exact path="/avisoPrivacidad" component={AvisoPrivacidad} />
          <Route exact path="/pago" component={Pago} />
          <Route path="/detalle" component={Detalle} />
          <Route component={NotFound} />
         
        </Switch>
      </ThemeProvider>
    </Context>
  </BrowserRouter>
);

export default Router;