import React,{useContext,useEffect} from 'react'
import {MyContext} from '../Context'
import {Text, Box,Button} from "@chakra-ui/core";
import DetalleArchivo from '../components/DetalleArchivo'

function Detalle({history}) {

    const context=useContext(MyContext)
    useEffect(()=>{
        if (!context.state.isLogged && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
            context.restoreData()
            .then(resp=>{
                const orden=localStorage.getItem('orden')
                if(!orden){
                    history.push('/perfil')
                }
            })
            .catch((err)=>history.push('/')) 
            }else{
                history.push('/')
            } 
        }
      })
    const {formOrden}=context.state
    return (
        <Box color="color5" display='flex' flexDirection='column' alignItems='center' >
        
            {context.state.isAdmin?
                <Text as='h1' fontSize='2xl' margin='20px 0' fontWeight='bold'>Detalle de la orden {formOrden.numero}</Text>
            :<Text as='h1' fontSize='2xl' margin='20px 0' fontWeight='bold'>Detalle de tu orden</Text>}
            <Box borderRadius='20px' border='solid 2px teal'  width='90vw'>
                <Box w='100%' borderTopLeftRadius='20px' borderTopRightRadius='20px' color='color6' backgroundColor='color3' padding='10px 20px' display='flex' justifyContent='space-between'>
                    {context.state.isAdmin?
                        <Text fontSize='xl' fontWeight='bold'>{formOrden.cliente.name}({formOrden.cliente.email})</Text>
                        :<Text fontSize='xl' fontWeight='bold'>Orden {formOrden.numero}</Text>}
                    {!formOrden.pagoPendiente?<Text fontWeight='bold'>Fecha de envío:{formOrden.fechaEnvio}</Text>:
                    <Text fontWeight='bold'>Fecha de envío: Pendiente</Text>
                    }
                    <Text fontWeight='bold'>Estatus: {formOrden.estatus}</Text>
                </Box>
                <Box textAlign='left' display='flex' flexDirection={{sm:'column',md:'row'}} justifyContent='space-between' padding='20px'paddingTop='5px'>
                    <Box width={{sm:'90%',md:'60%'}} display='flex' flexDirection='column'>
                        <Text fontSize='xl' fontWeight='bold'>Proyecto - {formOrden.descripcion}</Text>
                        <Text  fontSize='lg'>Lista de piezas</Text>
                        <Box  >
                            {formOrden.archivos.map((archivo,index)=>
                                <DetalleArchivo key={index} archivo={archivo} corte={formOrden.corte} isAdmin={context.state.isAdmin}/>
                            )}
                            
                        </Box>
                        </Box>
                    <Box w={{sm:'90%',md:'40%'}}>
                    <Text fontWeight='bold'>Dirección de envío</Text>
                        <Text mb={10}>Augusto Rodin 498, Insurgentes Mixcoac,03920</Text>
                        <Text fontWeight='bold'>Pago</Text>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Subtotal:</Text> <Text>${formOrden.subTotal.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Envio:</Text> <Text>${formOrden.envio.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        {formOrden.cargosAdicionales?
                            <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
                                <Text>Cargos Adicionales:</Text> 
                                <Text>${formOrden.cargosAdicionales.toLocaleString('es-MX')}</Text>
                            </Box>:
                            <></>
                        }
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Total:</Text> <Text>${formOrden.total.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text></Box>
                        
                        {!context.state.isAdmin &&
                            <Box marginTop='5px' display='flex' flexDirection='row' justifyContent='space-between' width='100%'><Text>Saldo disponible:</Text> 
                                {context.state.loggedUser && <Text>${context.state.loggedUser.credits.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>}    
                            </Box>
                        }
                        
                        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' margin='5px 0' fontWeight='bold'><Text>Total a pagar:</Text> <Text>${(formOrden.total-formOrden.totalPagado).toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text> </Box>
                        
                        <Button marginLeft={{sm:'40%',md:'11vw'}} size="sm" borderRadius='100px' backgroundColor='color3' color='color6' onClick={()=>context.state.isAdmin?(formOrden.corte?history.push('/resumenOrdenesCorte'):history.push('/resumenOrdenes')):history.push('/perfil')}>Regresar</Button>      
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Detalle
