import axios from 'axios';

require('dotenv').config();

const {
  WEB_DOMAIN
} = process.env;

let baseURL = `https://emisha3dprint.com/api/v1`;

const service = axios.create({ withCredentials: true, baseURL });

const MY_SERVICE = {
  test: async () => {
    return await service.get('/');
  },
  materials: async ()=>{
    return await service.get('/materials')
  },
  materialsCorte: async ()=>{
    return await service.get('/materialsCorte')
  },
  signup: async (user) => {
    return await service.post('/signup', user);
  },
  login: async (user) => {
    return await service.post('/login', user);
  },
  changeAddress:async(form)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.patch('/direccionUsuario',{direccion:{...form},IdUser});
  },
  profile: async (idUser) => {
    return await service.post('/profile', {IdUser:idUser});
  },
  transferCredit:async(form)=>{
    return await service.patch('/transferCredit',form);
  },
  restorePassword: async (form)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.patch('/changePassword',{...form,IdUser})
  },
  resetPassword: async(email)=>{
    return await service.post('/resetPassword',email)
  },
  logOut: async () => {
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/logout',{IdUser});
  },
  handleFile: async (form) =>{
    return await service.post('/file/add',form)
  },
  handleFileCorte: async (form) =>{
    return await service.post('/fileCorte/add',form)
  },
  order: async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/add',{...orden,IdUser})
  },
  orderCorte: async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/addCorte',{...orden,IdUser})
  },
  applyCredits:async(info)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/applyCredits',{...info,IdUser})
  },
  getOrder:async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/getOrder',{idOrder:orden,IdUser})
  },
  cancelOrder:async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/cancelOrder',{order:orden,IdUser})
  },
  updateOrder:async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.patch('/order/updateOrder',{order:orden,IdUser})
  },
  applyPayment: async (orden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/payOrder',{orden:orden,IdUser})
  },
  //admin
  printOrder:async (idorden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/admin/printOrder',{idorden,IdUser})
  },
  cancelarOrder:async (idorden,porcentaje)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/admin/cancelOrder',{idorden,porcentaje,IdUser})
  },
  fileStatus:async (file,estatus)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.patch('/admin/fileStatus',{file,estatus,IdUser})
  },

  distancia: async (direccion,idOrden)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/distance',{direccion,idOrden,IdUser})
  },
  updateTipoEnvio:async(infoEnvio,idOrden,subTotal)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/tipoEnvio',{infoEnvio,idOrden,subTotal,IdUser})
  },
  updateDireccionEnvio:async(nuevaDireccion,tipo,idOrden,subTotal,envio)=>{
    const IdUser=localStorage.getItem('idUser')
    return await service.post('/order/direccionEnvio',{nuevaDireccion,tipo,idOrden,subTotal,envio,IdUser})
  },
};

export default MY_SERVICE;
