import React,{useContext,useEffect} from 'react'
import {MyContext} from '../Context'
import {Flex,Text, Box,useToast,Divider, SimpleGrid,Tabs,Tab, TabList, TabPanels, TabPanel,Spinner,Link,
    Button,Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter, ModalBody, ModalCloseButton,useDisclosure,Select } from "@chakra-ui/core";
import LateralMenu from '../components/LateralMenu'
import { FaEdit } from "react-icons/fa";

function ResumenArchivos({history}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const context=useContext(MyContext)
    useEffect(()=>{
        if (!context.state.isLogged && context.state.feed) {
            const id=localStorage.getItem('idUser')
            if (id){
                context.restoreData()
                .catch((err)=>history.push('/'))
                 
            }else{
                history.push('/')
            }
        }
      })
    const toast = useToast()
    const [loading, setLoading] = React.useState(false);
    const [file,setFile] = React.useState(null);
    const [estatus,setEstatus] =React.useState('');
    const changeStatus=(archivo)=>{
        setFile(archivo);
        setEstatus(archivo.estatus)
        onOpen();
    }
    const handleSelect=(e)=>{
        setEstatus(e.target.value)
    }
    const submitStatus= ()=>{
        setLoading(true);
        context.fileStatus(file,estatus)
            .then(resp=>{
                toast({
                    title: 'Cambiar Estatus', 
                    description: resp.msg, 
                    status: 'success',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
            })
            .catch(err=>{
                toast({
                    title: 'Cambiar Estatus', 
                    description: 'No se pudo actualizar', 
                    status: 'error',
                    duration: 3000, 
                    isClosable: true,
                    position:'top-right'
                })
                setLoading(false);
            })
            
            onClose();
    }
    return (
        <>
            {context.state.isLogged && context.state.isAdmin && 
                <Box display='flex' flexDir='row' justifyContent='space-between' alignItems='flex-start' w='100vw' color='color5'>
                    <LateralMenu width={'15vw'} />
                    <Flex flexDir='column' w='80vw' paddingRight='5vw'>
                        <Text textAlign='left' color='color5' fontWeight='bold' fontSize='2xl'>Archivos Impresión 3D</Text>
                        <Divider border='solid 3px' color='color5' borderColor="color5" width='100%'/>
                        <Tabs size='md' variantColor='teal'>
                            <TabList>
                                <Tab>Pendientes</Tab>
                                <Tab>Imprimiendo</Tab>
                                <Tab>Listos</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={10} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center'>Archivo</Text>
                                        <Text justifySelf='center'>Material</Text>
                                        <Text justifySelf='center'>Relleno</Text>
                                        <Text justifySelf='center'>Calidad</Text>
                                        <Text justifySelf='center'>Cantidad</Text>
                                        <Text justifySelf='center'>Dimensiones</Text>
                                        <Text justifySelf='center'>Soporte</Text>
                                        <Text justifySelf='center'>Comentarios</Text>
                                        <Text justifySelf='center'>Editar Estatus</Text>
                                    </SimpleGrid>    
                                        {context.state.orders && 
                                            <>
                                                {!context.state.orders.impresionImprimiendo.length ?
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="4/6" textAlign='center'>No hay archivos</Text>
                                                    </SimpleGrid>
                                                    :
                                                    <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderColor='color5' borderTop='none'  borderRadius='0px 0px 20px 20px'>
                                                        {context.state.orders.impresionImprimiendo.map((orden,index)=>
                                                            <Box key={`I_${index}`} >
                                                                    {orden.archivos.map((archivo,index)=>
                                                                        <Box key={`RA_${index}`}>
                                                                            {!archivo.imprimiendo && !archivo.listo && 
                                                                                <SimpleGrid  textAlign='center' columns={10} borderBottom='solid 1px'  color='color3' borderColor='color5'>
                                                                                    <Text justifySelf='center'>{orden.numero}</Text>
                                                                                    <Link isExternal fontWeight='bold' href={archivo.fileURL}>{archivo.name}</Link>
                                                                                    <Text justifySelf='center'>{archivo.material}</Text>
                                                                                    <Text justifySelf='center'>{archivo.fill}</Text>
                                                                                    <Text justifySelf='center'>{archivo.quality}</Text>
                                                                                    <Text justifySelf='center'>{archivo.amount}</Text>
                                                                                    <Text justifySelf='center'>{`${archivo.dimX} x ${archivo.dimY} x ${archivo.dimZ} ${archivo.units}`}</Text>
                                                                                    <Text justifySelf='center'>{archivo.soporte?'Si':'No'}</Text>
                                                                                    <Text justifySelf='center'>{archivo.comments}</Text>
                                                                                    <Flex justifyContent='space-around' alignItems='center'>
                                                                                        <Box className='boton' as={FaEdit} onClick={e=>changeStatus(archivo)}/>  
                                                                                    </Flex>  
                                                                                </SimpleGrid>
                                                                            }  
                                                                        </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Box>      
                                                }
                                            </>
                                        }
                                </TabPanel>
                                
                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={10} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center'>Archivo</Text>
                                        <Text justifySelf='center'>Material</Text>
                                        <Text justifySelf='center'>Relleno</Text>
                                        <Text justifySelf='center'>Calidad</Text>
                                        <Text justifySelf='center'>Cantidad</Text>
                                        <Text justifySelf='center'>Dimensiones</Text>
                                        <Text justifySelf='center'>Soporte</Text>
                                        <Text justifySelf='center'>Comentarios</Text>
                                        <Text justifySelf='center'>Editar Estatus</Text>
                                    </SimpleGrid>    
                                        {context.state.orders && 
                                            <>
                                                {!context.state.orders.impresionImprimiendo.length ?
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="4/6" textAlign='center'>No hay archivos</Text>
                                                    </SimpleGrid>
                                                    :
                                                    <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderColor='color5' borderTop='none'  borderRadius='0px 0px 20px 20px'>
                                                        {context.state.orders.impresionImprimiendo.map((orden,index)=>
                                                            <Box key={`II_${index}`} >
                                                                    {orden.archivos.map((archivo,index)=>
                                                                        <Box key={`IA_${index}`}>
                                                                            {archivo.imprimiendo && 
                                                                                <SimpleGrid  textAlign='center' columns={10} borderBottom='solid 1px'  color='color3' borderColor='color5'>
                                                                                    <Text justifySelf='center'>{orden.numero}</Text>
                                                                                    <Link isExternal fontWeight='bold' href={archivo.fileURL}>{archivo.name}</Link>
                                                                                    <Text justifySelf='center'>{archivo.material}</Text>
                                                                                    <Text justifySelf='center'>{archivo.fill}</Text>
                                                                                    <Text justifySelf='center'>{archivo.quality}</Text>
                                                                                    <Text justifySelf='center'>{archivo.amount}</Text>
                                                                                    <Text justifySelf='center'>{`${archivo.dimX} x ${archivo.dimY} x ${archivo.dimZ} ${archivo.units}`}</Text>
                                                                                    <Text justifySelf='center'>{archivo.soporte?'Si':'No'}</Text>
                                                                                    <Text justifySelf='center'>{archivo.comments}</Text>
                                                                                    <Flex justifyContent='space-around' alignItems='center'>
                                                                                        <Box className='boton' as={FaEdit} onClick={e=>changeStatus(archivo)}/>  
                                                                                    </Flex>  
                                                                                </SimpleGrid>
                                                                            }  
                                                                        </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Box>      
                                                }
                                            </>
                                        }
                                </TabPanel>

                                <TabPanel paddingTop={5}>
                                    <SimpleGrid textAlign='center' columns={10} border='solid 1px' backgroundColor='color3' color='color6' borderColor='color5'  borderRadius='20px 20px 0 0'>
                                        <Text justifySelf='center'>#Orden</Text>
                                        <Text justifySelf='center'>Archivo</Text>
                                        <Text justifySelf='center'>Material</Text>
                                        <Text justifySelf='center'>Relleno</Text>
                                        <Text justifySelf='center'>Calidad</Text>
                                        <Text justifySelf='center'>Cantidad</Text>
                                        <Text justifySelf='center'>Dimensiones</Text>
                                        <Text justifySelf='center'>Soporte</Text>
                                        <Text justifySelf='center'>Comentarios</Text>
                                        <Text justifySelf='center'>Editar Estatus</Text>
                                    </SimpleGrid>    
                                        {context.state.orders && 
                                            <>
                                                {!context.state.orders.impresionImprimiendo.length ?
                                                    <SimpleGrid  columns={8} border='solid 1px' borderTop='none' color='color3' borderColor='color5' height='400px'  borderRadius='0px 0px 20px 20px' display='flex' alignItems='center' justifyContent='center'>
                                                        <Text gridColumn="4/6" textAlign='center'>No hay archivos</Text>
                                                    </SimpleGrid>
                                                    :
                                                    <Box mb={15} overflowY='scroll' height='400px' className='barras' border='solid 1px' borderColor='color5' borderTop='none'  borderRadius='0px 0px 20px 20px'>
                                                        {context.state.orders.impresionImprimiendo.map((orden,index)=>
                                                            <Box key={`L_${index}`} >
                                                                    {orden.archivos.map((archivo,index)=>
                                                                        <Box key={`LA_${index}`}>
                                                                            {archivo.listo && 
                                                                                <SimpleGrid  textAlign='center' columns={10} borderBottom='solid 1px'  color='color3' borderColor='color5'>
                                                                                    <Text justifySelf='center'>{orden.numero}</Text>
                                                                                    <Link isExternal fontWeight='bold' href={archivo.fileURL}>{archivo.name}</Link>
                                                                                    <Text justifySelf='center'>{archivo.material}</Text>
                                                                                    <Text justifySelf='center'>{archivo.fill}</Text>
                                                                                    <Text justifySelf='center'>{archivo.quality}</Text>
                                                                                    <Text justifySelf='center'>{archivo.amount}</Text>
                                                                                    <Text justifySelf='center'>{`${archivo.dimX} x ${archivo.dimY} x ${archivo.dimZ} ${archivo.units}`}</Text>
                                                                                    <Text justifySelf='center'>{archivo.soporte?'Si':'No'}</Text>
                                                                                    <Text justifySelf='center'>{archivo.comments}</Text>
                                                                                    <Flex justifyContent='space-around' alignItems='center'>
                                                                                        <Box className='boton' as={FaEdit} onClick={e=>changeStatus(archivo)}/>  
                                                                                    </Flex>
                                                                                </SimpleGrid>
                                                                            }  
                                                                        </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Box>      
                                                }
                                            </>
                                        }
                                </TabPanel>

                            
                            </TabPanels>
                        </Tabs>
                    </Flex>
                    <Modal preserveScrollBarGap isCentered={true} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                        <ModalHeader>Cancelar Orden</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody display='flex' flexDirection='column' justifyContent='space-around' alignItems='center'>
                            Elige un estatus
                            <Select size='sm' w='200px' display="flex" justifyContent="space-around" name="estatus" onChange={e => handleSelect(e)} value={estatus}>
                                <option value="Recibido">Recibido</option>
                                <option value="Imprimiendo">Imprimir</option>
                                <option value="Listo">Listo</option>
                                <option value="Cancelado">Cancelar</option>
                            </Select>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="ghost" size="sm" borderRadius='100px' width='150px' margin='5px 0' color='color3' mr={3} onClick={onClose}>Cancelar</Button>
                            <Button isDisabled={loading} size="sm" borderRadius='100px' width='150px' margin='5px 0' backgroundColor='color3' color='color6' onClick={submitStatus}>Procesar</Button>
                        </ModalFooter>
                        </ModalContent>
                    </Modal> 
                </Box>
            }
            {loading && 
                <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' position='fixed' top={0} zIndex="99" w='100vw' h='100vh' backgroundColor='color3' opacity='.7'>
                    <Text color='color1' fontWeight='bold' fontSize='5xl'>Procesando</Text>
                    <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="teal.700"
                    size="xl"
                    />
                </Box>
            }       
       </>
)}

export default ResumenArchivos
