import React from 'react'
import { withRouter } from 'react-router-dom'
import { Flex,Icon, Box, Image,Text, IconButton,Button, Menu,MenuButton,MenuDivider,MenuGroup,MenuItem,MenuList} from '@chakra-ui/core'
import { MyContext } from '../Context'
import { TiThMenu } from "react-icons/ti";
import Auth from './auth'
import { FaUserAlt } from "react-icons/fa";

function Navbar({ history }) {
  const go = path => history.push(path)
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <MyContext.Consumer>
      {context => {
        return (
          <>
            <Flex
            pos='fixed' top={0}
            width='100vw'
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            bg="color3"
            color="white"
            zIndex="99"
            >
              <Flex height='10vh' align="center" ml={5}>
                  <Image height={{sm:'7vh',md:"9vh"}} mt={{sm:'15px',md:'0'}}  src="/logoEDC.png" alt='logo' onClick={() => go('/')} />
              </Flex>

              <Box height='10vh' display={{ sm: "block", md: "none" }} onClick={handleToggle}>
                <IconButton
                marginTop='1.5vh'
                          variant="outline"
                          color="color4"
                          backgroundColor='color3'
                          aria-label="Menu"
                          size="lg"
                          icon={TiThMenu}
                          border="none"
                          marginRight='10px'
                        />
              </Box>
              <Box
                  zIndex={100}
                  display={{ sm: show ? "flex" : "none", md: "flex" }}
                  flexWrap='wrap'
                  flexDirection={{sm:'column',md:'row'}}
                  width={{ sm: "full", md: "auto" }}
                  alignItems="center"
                  justifyContent={{sm:'center',md:'flex-end'}}
                  flexGrow={1}
              >
                  <Text className='menuItems' mr={{sm:'0px',md:'15px'}} display="block" onClick={() => {go('/'); if (show) {handleToggle()}}}>Home</Text>
                  <Flex>
                    <Icon name="impresion" size="30px"></Icon> 
                    <Text className='menuItems' mr={{sm:'0px',md:'15px'}} display="block" onClick={() => {go('/cotizador'); if (show) {handleToggle()}}}>Cotizador 3D</Text>
                  </Flex>

       {/*           <Flex>
                    <Icon name="laser" size="30px"></Icon> 
                    <Text className='menuItems' mr={{sm:'0px',md:'15px'}} display="block" onClick={() => {go('/cotizadorCorte'); if (show) {handleToggle()}}}>Corte</Text>
                  </Flex>
      */}
                  <Text className='menuItems' mr={{sm:'0px',md:'15px'}} display="block" onClick={() => {go('/preguntasFrecuentes'); if (show) {handleToggle()}}}>FAQ's</Text>
                  {context.state.isLogged &&
                    <Menu >
                      <MenuButton className='menuItems' id='menuItem' size={{sm:'sm',md:'lg'}} variant="link" color="color4"  as={Button} rightIcon="chevron-down" mr={5}>
                        <Box as={FaUserAlt} mr={2} />Hola {context.state.loggedUser.name}
                      </MenuButton>
                      <MenuList color='color3'>
                        <MenuGroup title='Cuenta' alignSelf='flex-start'>
                          <MenuItem className='menuItems' color='color3' onClick={() =>{context.state.isAdmin?go('/perfilAdmin'):go('/perfil'); if (show) {handleToggle()}}}>Mi Perfil</MenuItem>
                          {context.state.isAdmin && 
                            <>
                              <MenuItem className='menuItems' color='color3' onClick={() =>{go('/resumenOrdenes'); if (show) {handleToggle()}}}>Ordenes Impresión</MenuItem>
                             {// <MenuItem className='menuItems' color='color3' onClick={() =>{go('/resumenOrdenesCorte'); if (show) {handleToggle()}}}>Ordenes Corte</MenuItem>
                             }<MenuItem className='menuItems' color='color3' onClick={() =>{go('/resumenArchivos'); if (show) {handleToggle()}}}>Archivos Impresión</MenuItem>
                            </>
                          }
                          <MenuItem className='menuItems' onClick={() => context.handleLogout()} >Cerrar Sesión</MenuItem>
                        </MenuGroup>
                        <MenuDivider />
                        <MenuItem className='menuItems' onClick={() => go('/preguntasFrecuentes')} >
                        Ayuda
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  }
                  {!context.state.isLogged &&
                    <>
                    <Auth value={<> <Box as={FaUserAlt} mr={2} />Hola</>} 
                    className='menuItems' mr={{sm:'0px',md:'25px'}} display='flex' flexDirection='row' alignItems='center' backgroundColor="color3" color="color6"/>
                    </>
                  }
              </Box>

            </Flex>
            
          </>
              )
        }}
    </MyContext.Consumer>
  )
}

export default withRouter(Navbar)