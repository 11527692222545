import React from 'react'
import { Box,Text,Link} from "@chakra-ui/core";

export default function DetalleArchivo({archivo,corte,isAdmin}) {
    return (
        <Box display='flex' flexDirection='column' color='color5' justifySelf='center' marginBottom='20px' border='solid 2px color3'>
            <Box w='100%' display='flex' flexDirection='row' justifyContent='space-between'>
                {isAdmin?
                    <Link isExternal fontWeight='bold' href={archivo.fileURL}>{archivo.name}</Link>
                    :<Text fontWeight='bold' >{archivo.name}</Text>}
            </Box>
            <Box w='100%' display='flex' dir='row' justifyContent='space-between' >
                <Box w='60%' display='flex' justifyContent='space-between' flexDirection='row' flexWrap='wrap' >
                    <Text  width='40%' fontSize='sm'>Cantidad: {archivo.amount}</Text>
                    <Text width='40%' fontSize='sm'>Material: {archivo.material}</Text>
                    {!corte && <><Text width='40%' fontSize='sm'>Resolución: {archivo.quality}</Text>
                    <Text width='40%' fontSize='sm'>Relleno: {archivo.fill}</Text></>}
                    <Text width='40%' fontSize='sm'>Color: {archivo.color}</Text>
                    {corte && <Text width='40%' fontSize='sm'>Grosor: {archivo.grosor}</Text>}
                    <Text width='40%' fontSize='sm'>Escala: {archivo.escala}%</Text>
                    {corte?<Text width='100%' fontSize='sm'>Dimensiones:{`${archivo.dimX} x ${archivo.dimY} ${archivo.units}`}</Text>:
                    <Text width='100%' fontSize='sm'>Dimensiones:{`${archivo.dimX} x ${archivo.dimY} x ${archivo.dimZ} ${archivo.units}`}</Text>}
                    {archivo.soporte && <Text width='100%' fontSize='sm' color='tomato'>Con soporte soluble</Text> }
                </Box>
                <Box w='30%'>
                    <Text fontWeight='bold' >Costo: ${archivo.cost.toLocaleString('es-MX',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text> 
                </Box>
            </Box>  
            <Box w='100%'>
                {archivo.flag && <Text color='tomato'>*Debes enviar este archivo por correo.</Text>}
                <Text>Comentarios:</Text>
                <Text fontSize='sm' maxW='70%'>{archivo.comments}</Text>                 
            </Box>          
        </Box>
    )
}

