import React,{useContext,useState} from 'react'
import {Box,Select, Input,Text, Flex,IconButton,Popover,PopoverBody,PopoverContent,PopoverArrow,
    PopoverCloseButton,PopoverHeader,PopoverTrigger,Textarea,Button,Image,Icon,useToast,
NumberInput,NumberInputField,InputRightElement,Slider,SliderFilledTrack,SliderTrack,SliderThumb, Switch} from '@chakra-ui/core'
import {MyContext} from '../Context'
import STLViewer from './Viewers/STLViewer'
import { FaPlus,FaMinus,FaInfo,FaPercent } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
//import OBJViewer from './Viewers/OBJViewer'

function FileViewer({formArchivo,index}) {
    const toast=useToast();
    const context=useContext(MyContext)
    const {materials}=context.state
    const removeFile=()=>{
        if (context.state.formArchivos.length===1){
            setUploadFile(false)    
            document.getElementById(`cost_${index}`).innerHTML='0.00'
            document.getElementById(`subTotal_${index}`).innerHTML='0.00'
            document.getElementById(`Total`).innerHTML='0.00'
            document.getElementById(`Dims_${index}`).innerHTML="X x Y x Z "
            document.getElementById(`warn_${index}`).innerHTML=""
        }
        
        document.getElementById(`archivo_${index}`).value=""
        context.removeFile(index)
    }
    const handleInput=async (e)=>{
      await context.handleInput(e,'formArchivos',index)
    }
    const handleSwitch=(e)=>{
        const d={target:{
            name:e.target.name,
            value:e.target.checked.toString().toLowerCase()==='true'
        }}
        handleInput(d)
    }
    const handleSelect=async (e)=>{
        await context.handleSelect(e,index)
      }
    
    const handleFile=(e)=>{
        let flag=false
        if (e.target.files[0].name.toLowerCase().endsWith('.stl')) {
        //document.getElementById(`msg_${index}`).innerHTML=' '
            //console.log('archivo',e.target.files[0]);
            if (e.target.files[0].size> 10000000){
                flag=true
            }
            context.handleFile(e,'formArchivos',flag,index)
            setUploadFile(true)
            context.state.formArchivos[index].type==='.stl'?setFileObj(false):setFileObj(true)
            if (flag){
                toast({
                title: 'Archivo pesado',
                description: 'Si vas a ordenar este archivo necesitarás enviarlo por correo.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position:'top-left'
                })
            }
        }
        else {
            toast({
                title: 'Formato incorrecto',
                description: 'El archivo que cargaste no es .STL.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position:'top-left'
              })
        }

    }
    const handleCount=(sign,e)=>{
        if(sign===1){
            const d={
                target:{
                    name:'amount',
                    value:formArchivo.amount+sign
                }
            }
            handleInput(d)
        }
        else if (sign===0){
            const d={
                target:{
                    name:'amount',
                    value:e.target.value===0?1:Math.abs(e.target.value)
                }
            }
            handleInput(d)
        }
        else if(sign===-1){
                const d={
                    target:{
                        name:'amount',
                        value:Math.max(formArchivo.amount+sign,1)
                    }
                }
                handleInput(d)

        }
        
    }
    const handleEscala=value=>{
        context.handleEscala(Math.round(value*100)/100,index)
    }
    const handleDim=(value,dim)=>{
        switch (dim){
            case 1:
                const x=document.getElementById(`DimX_${index}`).innerHTML
                context.handleEscala(Math.round((value/x)*10000)/100,index)
                break;
            case 2:
                const y=document.getElementById(`DimY_${index}`).innerHTML
                context.handleEscala(Math.round((value/y)*10000)/100,index)
                break;
            case 3:
                const z=document.getElementById(`DimZ_${index}`).innerHTML
                context.handleEscala(Math.round((value/z)*10000)/100,index)
                break;
            default:
                break;
        }
    }
    const [uploadFile,setUploadFile]=useState(false)
    const [fileObj,setFileObj]=useState(false)
    
    return (
        <Box display="flex" flexDirection={{sm:'column',md:'row'}} justifyContent={{sm:'center',md:'space-around'}} alignItems='center' color='color5' margin='15px 15px 30px'>
            <Box display="flex" flexDirection='column' justifyContent='space-between'>
                <input type="file" id={`archivo_${index}`} name='archivo' 
                            accept={'.Stl'}
                            onChange={(e) => {handleFile(e)}}  />
                            {/*accept={'.Obj,.Stl'}*/}
                {!uploadFile ?
                    <Button alignSelf='center' as='label' width='200px' size="md" borderRadius='100px' backgroundColor='color3' color='color6' leftIcon={MdFileUpload} htmlFor={`archivo_${index}`}>Carga tu .STL</Button>:
                    <Button alignSelf='center' as='label' width='200px' size="md" borderRadius='100px' backgroundColor='color3' color='color6' isDisabled={true}>Archivo Seleccionado</Button>
                }
                <Button alignSelf='center' size='sm' mt={2} width='200px' borderRadius='100px' backgroundColor='delete' color='color4' onClick={()=>removeFile()}><Icon name='delete' mr={2}></Icon>Borrar archivo</Button>
                
                <Box w={{sm:'80vw',md:'20vw'}} display='flex' justifyContent='space-between'>
                    <Box w='45%'>
                        <label mt={1} mb={1} htmlFor="material">Material</label>
                        <Select size='sm' value={formArchivo.index.material} name="material" className="select"  onChange={(e)=>handleSelect(e)}>
                            <option disabled>Selecciona un material</option>
                            {materials && materials.map((material,index)=>
                                <option key={index} value={index}>{material.name}</option>
                            )}      
                        </Select>
                    </Box>
                    <Box w='45%'>
                        <label mt={1} mb={1} htmlFor="color">Color</label>
                        <Select size='sm' value={formArchivo.index.color} name="color" className="select"  onChange={(e)=>handleSelect(e)}>
                            <option disabled>Selecciona un color</option>
                            {materials && materials[formArchivo.index.material].color.map((color,index)=>
                                <option key={index} value={index}>{color.name}</option>
                            )} 
                        </Select>
                    </Box>
                </Box>
                <Box w={{sm:'80vw',md:'20vw'}} display='flex' justifyContent='space-between'>
                    <Box w='45%'>
                        <Box mt={1} mb={1} display='flex' justifyContent='space-between'>
                            <Text> </Text>
                            <Text as='label' htmlFor="quality">Calidad</Text>
                            <Popover placement='right'>
                                <PopoverTrigger>
                                    <IconButton w='fit-content' color='color6' size='xs' isRound={true} variantColor='teal' icon={FaInfo} />
                                </PopoverTrigger>
                                <PopoverContent zIndex={4}>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>¿Qué calidad elegir?</PopoverHeader>
                                    <PopoverBody  fontSize='sm' display='flex' flexDirection='row' flexWrap='wrap'  justifyContent='flex-start'>
                                        <Box display='flex' alignItems='center'>
                                            <Image size="40px" src="./calidadbaja.png" alt="CalidadBaja"/>
                                            <Text>0.3mm - Estándar -</Text>
                                            <Text>Prototipos</Text>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <Image size="40px" src="./calidadmedia.png" alt="CalidadMedia"/>
                                            <Text>0.2mm - Media-</Text>
                                            <Text>Maquetas con detalles</Text>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <Image size="40px" src="./calidadalta.png" alt="CalidadAlta"/>
                                            <Text>0.1mm - Alta -</Text>
                                            <Text>Piezas mecánicas</Text>
                                        </Box>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Box> 
                        
                        <Select size='sm' fontSize='xs' display="flex" justifyContent="space-between" variantColor='teal' name="quality" onChange={e => handleSelect(e)} value={formArchivo.index.quality}>
                            <option disabled>Selecciona una calidad</option>
                            {materials && materials[formArchivo.index.material].quality.map((quality,index)=>
                                <option key={index} value={index}>{quality.name}</option>
                            )}
                        </Select>
                    </Box>
                    <Box w='45%'>
                        <Box mt={1} mb={1} display='flex' justifyContent='space-between'>
                            <Text> </Text>
                            <Text as='label' htmlFor="fill">Relleno</Text>
                            <Popover placement='right'>
                                <PopoverTrigger>
                                    <IconButton  w='fit-content' color='color6' size='xs' isRound={true} variantColor='teal' icon={FaInfo} />
                                </PopoverTrigger>
                                <PopoverContent zIndex={4}>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>¿Qué relleno elegir?</PopoverHeader>
                                    <PopoverBody fontSize='sm' textAlign='left' display='flex' flexDirection='row' flexWrap='wrap' justifyContent='flex-start'>
                                        <Box display='flex' alignItems='center' justifyContent='space-around'>
                                            <Image size="40px" src="./relleno0.png" alt="Relleno0"/>
                                            <Text w='80px'>0%</Text>
                                            <Text w='70px'>Sin relleno</Text>
                                            <Text w='50%'>*No es posible con todas las impresiones</Text>
                                        </Box>
                                        <Box display='flex'  alignItems='center' justifyContent='space-around'>
                                            <Image size="40px" src="./relleno20.png" alt="Relleno20"/>
                                            <Text w='80px'>20-30%</Text>
                                            <Text w='80px'>Bajo - </Text>
                                            <Text w='50%'>Recomendable</Text>
                                        </Box>
                                        <Box display='flex'  alignItems='center' justifyContent='space-around'>
                                            <Image size="40px" src="./relleno60.png" alt="Relleno60"/>
                                            <Text w='80px'>50-60%</Text>
                                            <Text w='80px'>Medio - </Text>
                                            <Text w='50%'>Piezas mecánicas</Text>
                                        </Box>
                                        <Box display='flex'  alignItems='center' justifyContent='space-around'>
                                            <Image size="40px" src="./relleno80.png" alt="Relleno80"/>
                                            <Text w='80px'>90-100%</Text>
                                            <Text w='80px'>Alto - </Text>
                                            <Text w='50%'>Piezas mecánicas de mayor resistencia</Text>
                                        </Box>
        
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Box> 
                        
                        <Select size='sm' display="flex" justifyContent="space-between" name="fill" onChange={e => handleSelect(e)} value={formArchivo.index.fill}>
                            <option disabled>Selecciona un % de relleno</option>
                            {materials && materials[formArchivo.index.material].fill.map((fill,index)=>
                                <option key={index} value={index}>{fill.name}</option>
                            )}
                        </Select>
                    </Box>
                </Box>

                <Box w={{sm:'80vw',md:'20vw'}} display='flex' flexDirection='column'>
                    <Text as='label'>Dimensiones</Text>
                    <Flex w='100%' mt={1} mb={1} justifyContent='space-between'>
                        <Flex justifyContent='flex-start' mr='5px'>
                            <NumberInput type='number' size='sm' min={0} step={1} precision={2}
                                maxW="80px" fontSize='sm'
                                value={formArchivo.escala}
                                onChange={handleEscala}
                                    name='escalaI'
                            >
                            <NumberInputField textAlign='left' />
                            <InputRightElement m='10px' children={<Box as={FaPercent} color="color3" />} />
                            </NumberInput>
                        </Flex>
                        <Slider color='teal' name='escala' size='sm'  w='60%' min={0} max={300}  step={1} flex="1" value={formArchivo.escala} onChange={handleEscala}>
                            <SliderTrack />
                            <SliderFilledTrack />
                            <SliderThumb
                            fontSize="xs"
                            fontWeight='bold'
                            width="32px"
                            height="20px"
                            children={`<>`}
                            />
                        </Slider>
                    </Flex>
                    <NumberInput border='none' name='dimX0' value={formArchivo.dimX0} overflow='hidden' w='0' h='0' id={`DimX_${index}`}/>
                    <NumberInput border='none' name='dimY0' value={formArchivo.dimY0} overflow='hidden' w='0' h='0' id={`DimY_${index}`}/>
                    <NumberInput border='none' name='dimZ0' value={formArchivo.dimZ0} overflow='hidden' w='0' h='0' id={`DimZ_${index}`}/>
                        
                    <Flex justifyContent='space-between' mt={1} mb={1}>
                        <Flex>
                            <NumberInput mr={1} name='dimX' value={formArchivo.dimX} fontSize='xs' w='55px' size='sm' min={0} id={`DimX2_${index}`} onChange={e=>handleDim(e,1)}> <NumberInputField textAlign='center'/> </NumberInput> x
                            <NumberInput mr={1} ml={1} name='dimY' value={formArchivo.dimY} fontSize='xs' w='55px' size='sm' min={0} id={`DimY2_${index}`} onChange={e=>handleDim(e,2)}> <NumberInputField textAlign='center'/> </NumberInput> x
                            <NumberInput mr={1} ml={1} name='dimZ' value={formArchivo.dimZ} fontSize='xs' w='55px' size='sm' min={0} id={`DimZ2_${index}`} onChange={e=>handleDim(e,3)}> <NumberInputField textAlign='center'/> </NumberInput> 
                        </Flex> 
                        <Select size='sm' w='70px' display="flex" justifyContent="space-around" name="units" onChange={e => handleInput(e)} value={formArchivo.units}>
                            <option value="mm">mm</option>
                            <option value="in">in</option>
                        </Select>
                    </Flex>
                </Box>
                {materials && <Flex justifyContent='center' mt={1} mb={1} >
                    <Switch isDisabled={!materials[formArchivo.index.material].soporte} size='sm' color='teal' value={formArchivo.soporte} isChecked={formArchivo.soporte} name='soporte' onChange={e=>handleSwitch(e)} />
                    <Text>Soporte soluble</Text>
                </Flex>}
                <label htmlFor="amount" mt={1} mb={1}>Cantidad</label>
                <Box display='flex' flexDirection='row' justifyContent='center'>
                    <IconButton color='color6' alignSelf='center' size='xs' marginRight='5px' isRound={true} variantColor="teal" fontSize="15px" icon={FaMinus} onClick={()=>{handleCount(-1)}}/>
                    <Input size='sm' width='70px' textAlign='center' type='number' name='amount' value={formArchivo.amount} onChange={(e)=>handleCount(0,e)}/> 
                    <IconButton color='color6' alignSelf='center' size='xs' marginLeft='5px' isRound={true} variantColor="teal" fontSize="15px" icon={FaPlus} onClick={()=>{handleCount(1)}}/>
                </Box>
            </Box>
            <Flex display='flex' flexDirection='column' justify='space-between'>
                    <Text isTruncated={true}>Archivo: {formArchivo.name}</Text>
                {!uploadFile && 
                    <Box width='350px' height='350px' backgroundColor='#EAEAEA'>    
                    </Box>
                }
                {uploadFile && fileObj ?
                    <STLViewer
                        onSceneRendered={(element) => {
                            //console.log(element)
                        }}
                        sceneClassName="test-scene"
                        file={formArchivo.file}
                        className="obj"
                        units={formArchivo.units}
                        amount={formArchivo.amount}
                        modelColor={formArchivo.colorHEX}
                        material={formArchivo.material}
                        quality={formArchivo.quality}
                        fill={formArchivo.fill}
                        index={index}
                        costs={formArchivo.costs}
                        maxIndex={context.state.formArchivos.length-1}
                        escala={formArchivo.escala}
                        soporte={formArchivo.soporte}
                        /> : null

                }
                    <Text>Dimensiones: <span id={`Dims_${index}`}> X x Y x Z</span>{formArchivo.units}</Text>
                </Flex> 
                <Box display="flex" flexDirection='column' >
                    <Button marginBottom={{sm:'20px',md:'10vh'}} borderRadius='100px' leftIcon="small-close" variantColor="teal" variant="outline" onClick={()=>removeFile()}>
                        Eliminar archivo
                    </Button>
                    <Box display="flex" flexDirection='column' textAlign='left'>
                        <Text fontWeight='bold'>Comentarios:</Text>
                        <Textarea width='200px' height='75px' resize='none' name='comments'  value={formArchivo.comments}
                            onChange={e => handleInput(e)}
                            placeholder="Cuéntanos si tienes algún requerimiento especial :)"
                            size="sm"/>
                    </Box>
                    <br />
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='200px'><Text>Costo por pieza:</Text> <Text >$<span id={`cost_${index}`}>0.00</span></Text></Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='200px'><Text>Total piezas:</Text> <Text>{formArchivo.amount}</Text></Box>
                    <Box mt={10} mb='10vh' display='flex' flexDirection='row' justifyContent='space-between' width='200px' fontWeight='bold'><Text>Subtotal:</Text> <Text fontWeight='bold' >$<span id={`subTotal_${index}`}>0.00</span></Text></Box>
                    <Text maxW='200px' id={`warn_${index}`} color='tomato' fontWeight='bolder'> </Text>

                </Box> 

            
            {/*uploadFile && fileObj ?
                            <OBJViewer
                                onSceneRendered={(element) => {
                                    //console.log(element)
                                }}
                                sceneClassName="test-scene"
                                file={formArchivo.file}
                                className="obj"
                                units={formArchivo.units}
                                modelColor={formArchivo.color}/> : null
                        */
                        }
                
        </Box>   
    )
}

export default FileViewer





