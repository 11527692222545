import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import paypal from 'paypal-checkout';
import { MyContext } from '../Context'
import { useToast} from "@chakra-ui/core";


//se inicia funcion de paypalbutton
const PaypalCheckoutButton = ({ order }) => {
    const toast= useToast()
    const context=useContext(MyContext)
    
    const registraPago=async (data)=>{
            const msg=await context.applyPayment(data)
            console.log(msg)
            if(!msg){
                toast({
                    title: "Operación exitosa.",
                    description: `Compra con Paypal, ${data.id}.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position:'bottom-right'
                  })
            }
            else{
                toast({
                    title: 'Operación fallida', 
                    description: msg, 
                    status: 'error',
                    duration: 5000, 
                    isClosable: true,
                    position:'bottom-right'
                  })
        }
    }
  
    //


  const paypalConf = {
    currency: 'MXN',
    env: 'production',
    client: {
      sandbox: `AQmsuPGWMpociJUxcUEjuM9gzaLkTZow2QFNSOCj2OJqBTWs0hr0_UYkO_3-K-qsSXuQnRUqOiwB28OD`,
      production: 'AVteXXclrLKuirkTw_Ag5ArZjlxb4Psx8KBOkCLy0HvVhiIgqsNg5gHFbPZC5l2kt72yQiJlWL4Pnato',
    },
    style: {
      label: 'pay',
      size: 'medium', // small | medium | large | responsive
      shape: 'pill',   // pill | rect
      color: 'blue',  // gold | blue | silver | black 
      tagline: 'false', //etiqueta inferior
    },
  };

  //const client =
  //  process.env.token === "production" ? client.production : client.sandbox;
  /*
 const CLIENT = {
   sandbox:
     "AQmsuPGWMpociJUxcUEjuM9gzaLkTZow2QFNSOCj2OJqBTWs0hr0_UYkO_3-K-qsSXuQnRUqOiwB28OD",
   production:
     "your_production_key"
 };

 const CLIENT_ID =
 process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;
*/
  

  const PayPalButton = paypal.Button.driver('react', { React, ReactDOM });

  const payment = (data, actions) => {
      
    const payment = {
      transactions: [
        {
          amount: {
            total: Math.round((order.total-order.totalPagado)*100)/100,
            currency: paypalConf.currency,
          },
          description: `Orden: ${data.numero}`,
          custom: order.customer || '',
          item_list: {
            items: order.items
          },
        },
      ],
      note_to_payer: 'Contáctanos para cualquier aclaración.',
    };

    
    return actions.payment.create({
      payment,
    });
  };

  const onAuthorize = (data, actions) => {
      
    return actions.payment.execute()
      .then(response => {
       registraPago(response)
      })
      .catch(error => {
        console.log(error);
	      alert('Ocurrió un error al procesar el pago con Paypal');
      });
  };

  const onError = (error) => {
    toast({
        title: 'El pago con PayPal no fue realizado, vuelva a intentarlo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position:'top-right'
      })
  };

  const onCancel = (data, actions) => {
    toast({
        title: 'Proceso de pago cancelado.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position:'bottom-right'
      })
  };

//se llaman las funciones
  return (
    <PayPalButton
      env={paypalConf.env}
      client={paypalConf.client}
      payment={(data, actions) => payment(data, actions)}
      onAuthorize={(data, actions) => onAuthorize(data, actions)}
      onCancel={(data, actions) => onCancel(data, actions)}
      onError={(error) => onError(error)}
      style={paypalConf.style}
      commit
      locale="es_MX"
    />

  );
}

export default PaypalCheckoutButton;